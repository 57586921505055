import * as types from "./../actions/types";
import * as config from "./../config";

const initalState = {
  containerHeight: window.innerHeight,
  activeMenuItem: "locations",
  isUserMenuOpen: false,
  isMobileMenuOpen: false,
  showSpinner: false,
  noOfActions: 0,
  isForgotActive: false,
  showNewBuildTicker: false,
  notification: {
    message: "",
    isVisible: false,
    isError: false,
    isSticky: false,
    hideAfter: config.DEFAULT_NOTIFICATION_HIDE_TIMEOUT,
  },
  flashMessage: {
    duration: 2000,
    status: "",
    show: false,
    message: "",
    subMessage: "",
    childElement: null,
    onClick: null,
    width: null,
    backdrop: false,
    showClose: true,
  },
  modal: {
    show: false,
    content: null,
  },
  menuItems: [
    {
      name: "locations",
      url: "/locations",
    },
    {
      name: "schedules",
      url: "/schedules",
    },
    {
      name: "playlists",
      url: "/playlists",
    },
  ],
};

const layoutReducer = (state = initalState, action) => {
  switch (action.type) {
    case types.CHANGE_CONTAINER_HEIGHT:
      return Object.assign({}, state, {
        containerHeight: action.payload,
      });
    case types.CHANGE_ACTIVE_MENU_ITEM:
      return Object.assign({}, state, {
        activeMenuItem: action.payload,
      });
    case types.CHANGE_USER_MENU:
      return Object.assign({}, state, {
        isUserMenuOpen: action.payload,
      });
    case types.CHANGE_MOBILE_MENU:
      return Object.assign({}, state, {
        isMobileMenuOpen: action.payload,
      });
    case types.CHANGE_FORGOT:
      return Object.assign({}, state, {
        isForgotActive: action.payload,
      });
    case types.SHOW_SPINNER:
      return Object.assign({}, state, {
        showSpinner: true,
        noOfActions: state.noOfActions + 1,
      });
    case types.HIDE_SPINNER:
      return Object.assign({}, state, {
        showSpinner: false,
        noOfActions: state.noOfActions - 1,
      });
    case types.SHOW_NOTIFICATION:
      return Object.assign({}, state, {
        notification: {
          ...state.notification,
          ...action.payload,
          isVisible: true,
        },
      });
    case types.HIDE_NOTIFICATION:
      return Object.assign({}, state, {
        notification: { ...state.notification, isVisible: false },
      });
    case types.SHOW_MODAL:
      return {
        ...state,
        modal: {
          show: true,
          content: payload,
        },
      };
    case types.HIDE_MODAL:
      return {
        ...state,
        modal: {
          ...initalState.modal,
        },
      };
    case types.CHECK_BUILD:
      return Object.assign({}, state, {
        showNewBuildTicker: !(config.BUILD_VERSION === action.payload),
      });
    case types.SHOW_FLASH_MESSAGE:
      return Object.assign({}, state, {
        flashMessage: {
          duration: action.payload.duration || 2000,
          status: action.payload.status,
          message: action.payload.message,
          subMessage: action.payload.subMessage,
          childElement: action.payload.childElement,
          onClick: action.payload.onClick,
          width: action.payload.width,
          backdrop: action.payload.backdrop,
          show: true,
          showClose: action.payload.showClose,
        },
      });
    case types.HIDE_FLASH_MESSAGE:
      return Object.assign({}, state, {
        flashMessage: {
          ...state.flashMessage,
          show: false,
        },
      });
  }

  return state;
};

export default layoutReducer;
