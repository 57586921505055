import * as types from "./../actions/types";
import { LOCAL_STORAGE_USER } from "./../config";
import { getFreezeClient, getFreezeModal } from "../utils/localStorage";

const storedUserInfo = JSON.parse(localStorage.getItem(LOCAL_STORAGE_USER)) || {};
const freezeClient = getFreezeClient();
const freezeModal = getFreezeModal();
const initalState = {
  userInfo: {
    created: storedUserInfo.created,
    userToken: storedUserInfo.userToken,
    userType: storedUserInfo.userType,
    userName: storedUserInfo.userName,
    userId: storedUserInfo.userId,
    clientId: storedUserInfo.clientId,
    device: storedUserInfo.device,
    businessName: storedUserInfo.businessName,
    businessImage: storedUserInfo.businessImage,
    username: storedUserInfo.username,
    isFrozen: storedUserInfo.isFrozen,
    refreshToken: storedUserInfo.refreshToken,
  },
  showMigrationBar: false,
  showMigrationModal: false,
  freezeClientInfo: {
    status: (freezeClient && freezeClient.status) || false,
    allLocations: (freezeClient && freezeClient.allLocations) || false,
    show: (freezeClient && freezeClient.show) || true,
  },

  showFreezeModal: freezeModal || true,
};

const authReducer = (state = initalState, action) => {
  switch (action.type) {
    case types.LOGIN_SUCCESS:
      //debugger
      return Object.assign({}, state, {
        userInfo: action.payload,
      });
      break;
    case types.LOGOUT_SUCCESS:
      return Object.assign({}, state, {
        userInfo: {
          userToken: undefined,
        },
      });
      break;

    case types.FREEZE_CLIENT:
      return Object.assign({}, state, {
        freezeClientInfo: action.payload,
      });
      break;

    case types.SHOW_FREEZE_MODAL:
      return Object.assign({}, state, {
        showFreezeModal: action.payload,
      });
      break;

    case types.SHOW_MIGRATION_WARNING_BAR:
      return { ...state, showMigrationBar: action.payload };
      break;

    case types.SHOW_MIGRATION_WARNING_MODAL:
      return { ...state, showMigrationModal: action.payload };
  }

  return state;
};

export default authReducer;
