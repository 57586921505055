import * as types from "./types";

export const changeIsAdminApprovalNeeded = (payload) => ({
  type: types.CHANGE_IS_ADMIN_APPROVAL_NEEDED,
  payload,
});

export const changeIsMediaPlaying = (payload) => ({
  type: types.CHANGE_IS_MEDIA_PLAYING,
  payload,
});
