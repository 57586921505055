import * as React from "react";

import { resetPassword, validatePasswordResetToken } from "@api/auth-api";

import * as gaAnalytics from "@common/gaAnalyticsHelper";
import LoginSplitContainer from "./login-split-container";

class SetPassword extends React.Component {
  state = {
    token: "",
    password: "",
    passwordConfirm: "",
    showPassword: false,
    isChanged: false,
    isLoaded: false,
    isTokenValid: true,
    email: "",
    user: {},
  };

  async componentWillMount() {
    const token = this.props.location.search ? this.props.location.search.replace("?token=", "") : null;
    if (token) {
      const response = await validatePasswordResetToken(token);
      const user = { ...response };
      const isTokenValid = response.email;
      mixpanel.track_pageview();
      gaAnalytics.trackPage(this.props.location.pathname);
      this.setState({
        ...this.state,
        user,
        token,
        isTokenValid,
        email: response.email,
        isLoaded: true,
      });
    } else {
      this.setState({
        ...this.state,
        user: {},
        token: "",
        isTokenValid: false,
        isLoaded: true,
      });
    }
  }

  get jsxContent() {
    return {
      changePassword: (
        <React.Fragment>
          <p style={{ fontSize: "14px" }}>{this.state.user.name}</p>
          <p style={{ fontSize: "14px" }}>{this.state.user.email}</p>
          <h3>Choose a password</h3>
          <div className="login-inputs-wrapper">
            <div className="login-label">New Password</div>
            <input
              onChange={this.handleOnChangePassword}
              className={
                this.state.password !== "" && !this.isPasswordValid(this.state.password) ? "login-input-invalid" : ""
              }
              autoCapitalize="none"
              type={this.state.showPassword ? "text" : "password"}
            />

            <div className="login-label">Confirm new Password</div>
            <input
              onChange={this.handleOnChangePasswordConfirm}
              className={
                !this.isPasswordMatching || !this.isPasswordValid(this.state.passwordConfirm)
                  ? "login-input-invalid"
                  : ""
              }
              autoCapitalize="none"
              type={this.state.showPassword ? "text" : "password"}
            />
            <div className="new-password-toggle">
              <input
                type="checkbox"
                id="show-password"
                className="checkbox-switch"
                onChange={this.handlePasswordToggle}
              />
              <label htmlFor="show-password" />
              {this.state.showPassword ? "Hide Password" : "Show Password"}
            </div>
            {(this.state.password.length > 0 && this.state.password.length < 8) ||
            (this.state.passwordConfirm.length > 0 && !this.isPasswordMatching) ? (
              <div
                className="alert-danger"
                style={{
                  margin: "0 0 2rem 0",
                  padding: "5px",
                  borderRadius: "5px",
                  width: "100%",
                }}
              >
                {this.state.password.length > 0 && this.state.password.length < 8 ? (
                  <p style={{ margin: "5px" }}>
                    <small>Password must be 8 characters or greater.</small>
                  </p>
                ) : null}
                {this.state.passwordConfirm.length > 0 && !this.isPasswordMatching ? (
                  <p style={{ margin: "5px" }}>
                    <small>Passwords do not match.</small>
                  </p>
                ) : null}
              </div>
            ) : null}
            <div>
              <button
                disabled={!this.isFormValid || this.state.isSubmitted}
                className="btn btn-primary btn-block btn-lg"
                onClick={this.handleSubmit}
              >
                CHANGE
              </button>
            </div>
          </div>
        </React.Fragment>
      ),
      invalidLink: (
        <React.Fragment>
          <h1>Invalid Link</h1>
          <div className="login-inputs-wrapper">
            <p>If you know your password, please log in. Otherwise, create a new password reset request</p>
            <button style={{ marginRight: "1em" }} className="btn btn-primary" onClick={this.handleGoToLogin}>
              GO TO LOGIN
            </button>
          </div>
        </React.Fragment>
      ),
      passwordChangeSuccess: (
        <React.Fragment>
          <h1>Password successfully changed</h1>
          <div className="login-inputs-wrapper">
            <p>You can now log in with your new password</p>
            <button style={{ marginRight: "1em" }} className="btn btn-primary" onClick={this.handleGoToLogin}>
              GO TO LOGIN
            </button>
          </div>
        </React.Fragment>
      ),
      passwordChangeFail: (
        <React.Fragment>
          <h1>Error trying to set new password</h1>
          <div className="login-inputs-wrapper">
            <p>Please create a new password reset request and try again</p>
            <button style={{ marginRight: "1em" }} className="btn btn-primary" onClick={this.handleGoToLogin}>
              GO TO LOGIN
            </button>
          </div>
        </React.Fragment>
      ),
    };
  }

  get content() {
    if (!this.state.isTokenValid) {
      return this.jsxContent.changePassword;
    }
    if (!this.state.isChanged) {
      return this.jsxContent.changePassword;
    }
    if (this.state.isSubmitted && this.state.isChanged) {
      return this.jsxContent.passwordChangeSuccess;
    }
    return this.jsxContent.passwordChangeFail;
  }

  get isPasswordMatching() {
    if (this.state.passwordConfirm === "") return true;
    return this.state.password === this.state.passwordConfirm;
  }

  get isFormValid() {
    return (
      this.isPasswordValid(this.state.password) &&
      this.isPasswordValid(this.state.passwordConfirm) &&
      this.isPasswordMatching
    );
  }

  isPasswordValid = (password) => {
    const re = new RegExp(/([\d\w\W]){8,}/);
    return password === "" || re.test(password);
  };

  handlePasswordToggle = () => {
    const showPassword = !this.state.showPassword;
    this.setState({
      ...this.state,
      showPassword,
    });
  };

  handleOnChangePassword = (e) => {
    const password = e.target.value;
    this.setState({
      ...this.state,
      password,
    });
  };

  handleOnChangePasswordConfirm = (e) => {
    const passwordConfirm = e.target.value;
    this.setState({
      ...this.state,
      passwordConfirm,
    });
  };

  handleSubmit = async () => {
    const isSubmitted = true;
    this.setState({
      ...this.state,
      isSubmitted,
    });
    const isChanged = await resetPassword(
      this.state.token,
      this.state.password,
      this.state.passwordConfirm,
      this.state.email
    );
    this.setState({
      ...this.state,
      isChanged,
    });
  };

  handleKeyPress(e) {
    if (e.charCode === 13) {
      this.handleSubmit();
    }
  }

  handleGoToLogin = () => this.props.history.push("/login");

  render() {
    return (
      <LoginSplitContainer onKeyPress={this._handleKeyPress} id="login-wrapper" showFooter>
        {this.state.isLoaded ? (
          <div className="login-container">
            <div id="login-box" className="col-lg-12">
              {this.content}
            </div>
          </div>
        ) : null}
      </LoginSplitContainer>
    );
  }
}

export default SetPassword;
