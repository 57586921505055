import store from "./../store";
import * as playlistsAction from "./../actions/playlists-actions";
import { showSpinner, hideSpinner, showNotification } from "./../actions/layout-actions";
import { GENERAL_REQUEST_ERROR_MESSAGE, getEnvironment } from "./../config";
import * as authApi from "./auth-api";
import axiosInterceptors from "../utils/interceptors";

/**
 * @param {String} userToken
 */
export function getMyPlaylists() {
  store.dispatch(showSpinner());

  return axiosInterceptors
    .get(`${getEnvironment().HOST_ROCK_API}/playlists/dashboard/client/with-tracks`, {})
    .then((response) => {
      const playlists = [...response.data.playlists];

      store.dispatch(playlistsAction.addPlaylists(playlists));

      store.dispatch(hideSpinner());
    })
    .catch((response) => {
      store.dispatch(hideSpinner());
      store.dispatch(showNotification(GENERAL_REQUEST_ERROR_MESSAGE, true));
      _handleUnauthorized(response.data.error);
    });
}

/**
 * @param {String} userToken
 * @param {Number} playlistId
 */
export function getStreamingURL(playlistId) {
  return new Promise((resolve, reject) => {
    return axiosInterceptors
      .get(`${getEnvironment().HOST_ROCK_API}/playlists/preview/${playlistId}`, {})
      .then((response) => {
        resolve(response.data.preview);
      })
      .catch((response) => {
        store.dispatch(showNotification(GENERAL_REQUEST_ERROR_MESSAGE, true));
        _handleUnauthorized(response.data.error);
        reject(response);
      });
  });
}

function _handleUnauthorized(error) {
  if (error === "Unauthorized" || error === "Invalid token" || error === 401) {
    authApi.logout();

    // redirect page to login screen
    let rootPath = window.location.href.split("/", 1);
    window.location.href = rootPath + "/login";
    return false;
  }
  return true;
}
