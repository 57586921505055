import React from "react";

const SmartphoneSvg = (props) => (
  <svg
    viewBox="0 0 128 128"
    fill={props.fill || "none"}
    stroke={props.stroke || "none"}
    strokeWidth={props.strokeWidth || 1}
    width={props.width}
    height={props.height}
  >
    <path d="m81.5 17.25h-36.521a9.761 9.761 0 0 0 -9.75 9.75v74a9.761 9.761 0 0 0 9.75 9.75h36.521a9.761 9.761 0 0 0 9.75-9.75v-74a9.761 9.761 0 0 0 -9.75-9.75zm-7.284 3.5v2.262a1.726 1.726 0 0 1 -1.724 1.723h-18.5a1.725 1.725 0 0 1 -1.723-1.723v-2.262zm13.533 80.25a6.257 6.257 0 0 1 -6.25 6.25h-36.52a6.257 6.257 0 0 1 -6.25-6.25v-74a6.257 6.257 0 0 1 6.25-6.25h3.786v2.262a5.229 5.229 0 0 0 5.223 5.223h18.5a5.23 5.23 0 0 0 5.224-5.223v-2.262h3.788a6.257 6.257 0 0 1 6.249 6.25z"/><path d="m74 98.917h-21.521a1.75 1.75 0 0 0 0 3.5h21.521a1.75 1.75 0 0 0 0-3.5z"/>
  </svg>
);

export default SmartphoneSvg;
