import * as types from "./../actions/types";

const initalState = {
  isLoginValid: true,
  showShakeAnimation: false,
  passwordIsVisible: false,
};

const loginReducer = (state = initalState, action) => {
  switch (action.type) {
    case types.TOGGLE_PASSWORD_VISIBILITY:
      return Object.assign({}, state, {
        passwordIsVisible: !state.passwordIsVisible,
      });
      break;

    case types.SET_LOGIN_VALID:
      return Object.assign({}, state, {
        isLoginValid: true,
      });
      break;

    case types.SET_LOGIN_INVALID:
      return Object.assign({}, state, {
        isLoginValid: false,
        showShakeAnimation: true,
      });
      break;

    case types.SET_SHAKE_ANIMATION_OFF:
      return Object.assign({}, state, {
        showShakeAnimation: false,
      });
      break;
  }

  return state;
};

export default loginReducer;
