import * as types from "./types";

export function updateField(field, value) {
  return {
    type: types.UPDATE_CURATION_FIELD,
    payload: {
      field,
      value,
    },
  };
}

export function curationSent() {
  return {
    type: types.CURATION_SENT,
  };
}

export function curationSavingError() {
  return {
    type: types.CURATION_SAVING_ERROR,
  };
}

export function closeModals() {
  return {
    type: types.CLOSE_CURATION_MODALS,
  };
}

export function toggleAffectedLocation(location) {
  return {
    type: types.CURATION_TOGGLE_AFFECTED_LOCATION,
    payload: location,
  };
}

export const clearFields = () => ({
  type: types.CLEAR_FIELDS,
});

export const changeSubmittedCurationRequests = (payload) => ({
  type: types.CHANGE_SUBMITTED_CURATION_REQUESTS,
  payload,
});
