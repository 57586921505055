import store from "@store";
import { getEnvironment } from "../config";
import * as curationActions from "@actions/curation-actions";
import * as layoutActions from "@actions/layout-actions";
import axiosInterceptors from "../utils/interceptors";

export function saveCurationRequest(data) {
  store.dispatch(layoutActions.showSpinner());
  return axiosInterceptors
    .post(`${getEnvironment().HOST_ROCK_API}/briefs`, data)
    .then((response) => {
      store.dispatch(layoutActions.hideSpinner());
      store.dispatch(curationActions.curationSent());
    })
    .catch((response) => {
      store.dispatch(layoutActions.hideSpinner());
      store.dispatch(curationActions.curationSavingError());
      console.error("Error: ", response);
    });
}

export const getCurationRequests = async (clientId) => {
  try {
    const response = await axiosInterceptors.get(
      `${getEnvironment().HOST_ROCK_API}/briefs/client/dashboard/${clientId}`,
      {}
    );
    const requests = response.data;
    console.log(requests);
    store.dispatch(curationActions.changeSubmittedCurationRequests(requests));
  } catch (e) {
    console.log("TCL: getCurationRequests -> e", e);
  }
};
