import * as types from "../actions/types";
import moment from "moment-timezone";

const _generateTimezones = () => {
  const today = moment();
  return moment.tz
    .names()
    .map((tz) => {
      const zone = moment.tz.zone(tz);
      const offsetHoursAndMins = (zone.utcOffset(moment().utc()) / 60) * -1;
      const offsetHours = Math.floor(offsetHoursAndMins);
      const offsetMins = (Math.abs(offsetHoursAndMins) - Math.abs(offsetHours)) * 60;
      const offsetSymbol = offsetHours >= 0 ? "+" : "";
      const offsetDisplay = `GMT ${offsetSymbol + offsetHours}:${
        Math.abs(offsetMins) < 10 ? `0${Math.abs(offsetMins)}` : Math.abs(offsetMins)
      }`;
      return {
        name: tz,
        offsetHours,
        offsetMins: offsetHours > 0 ? offsetMins : -offsetMins,
        offsetHoursAndMins,
        offsetDisplay,
      };
    })
    .sort((a, b) => a.offsetHoursAndMins - b.offsetHoursAndMins);
};

const _getTimezoneByName = (timezoneName) => {
  return _generateTimezones().filter(
    (tz) =>
      tz.name.toLowerCase() === timezoneName.toLowerCase() ||
      tz.name.toLowerCase() === timezoneName.toLowerCase().replace(" ", "_")
  )[0];
};

const schedulesState = {
  isScheduleEditMode: false,
  isEditPlaylist: false,
  isExpandedView: false,
  showScheduleEditPopup: false,
  showScheduleSavePopup: false,
  showScheduleLocationsPopup: false,
  showScheduleDeleteConfirmationPopup: false,
  showScheduleOverlapsConfirmationPopup: false,
  showDiscardScheduleEditPopup: false,
  showAddNewSchedulePopup: false,
  showDeleteSchedulePopup: false,
  isScheduleEdited: false,
  isAddEditSchedValid: true,
  editScheduleObject: {
    day: 0,
    days: [true, false, false, false, false, false, false],
    startValue: 0,
    endValue: 1,
    channel: "",
  },
  schedules: [],
  selectedSchedule: {
    name: "",
    schedule: [[], [], [], [], [], [], []],
    timezoneAdjustedSchedule: [[], [], [], [], [], [], []],
  },
  currentChannelsForClient: [],
  days: ["Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday", "Sunday"],
  timezone: _getTimezoneByName("Europe/London"),
  timezoneName: "Europe/London",
  timezoneOffset: {
    hours: 0,
    minutes: 0,
  },
  timezones: _generateTimezones(),
  defaultTimezoneName: "Europe/London",
  defaultTimezone: _getTimezoneByName("Europe/London"),
  defaultTimezoneOffsetDisplay: _getTimezoneByName("Europe/London").offsetDisplay,
  showTimezoneModal: false,
  times: [],
  selectedLocation: "",
  addScheduleFromPlaylist: { playlist: "", selectedOption: "" },
};

export default (state = schedulesState, action) => {
  switch (action.type) {
    case types.CHANGE_SELECTED_SCHEDULE:
      let selectedSchedule = action.payload;
      selectedSchedule.timezoneAdjustedSchedule = action.payload.timezoneAdjustedSchedule ||
        state.timezoneAdjustedSchedule || [[], [], [], [], [], [], []];
      return { ...state, selectedSchedule };
    case types.CHANGE_SCHEDULES:
      return { ...state, schedules: action.payload };
    case types.CHANGE_SCHEDULE_EDIT_MODE:
      return {
        ...state,
        isScheduleEditMode: action.payload,
        isExpandedView: action.payload,
      };
    case types.CHANGE_SHOW_SCHEDULE_POPUP:
      return { ...state, showScheduleEditPopup: action.payload };
    case types.CHANGE_EDIT_SCHEDULE_OBJ:
      return { ...state, editScheduleObject: action.payload };
    case types.CHANGE_SHOW_SAVE_SCHEDULE_POPUP:
      return { ...state, showScheduleSavePopup: action.payload };
    case types.CHANGE_SHOW_SCHEDULE_LOCATIONS_POPUP:
      return { ...state, showScheduleLocationsPopup: action.payload };
    case types.CHANGE_SHOW_SCHEDULE_DELETE_CONFIRMATION_POPUP:
      return { ...state, showScheduleDeleteConfirmationPopup: action.payload };
    case types.CHANGE_SHOW_DISCARD_SCHEDULE_EDIT:
      return { ...state, showDiscardScheduleEditPopup: action.payload };
    case types.CHANGE_IS_SCHEDULE_EDITED:
      return { ...state, isScheduleEdited: action.payload };
    case types.CHANGE_SHOW_SCHEDULE_OVERLAPS_CONFIRMATION_POPUP:
      return {
        ...state,
        showScheduleOverlapsConfirmationPopup: action.payload,
      };
    case types.CHANGE_IS_EDIT_PLAYLIST:
      return { ...state, isEditPlaylist: action.payload };
    case types.CHANGE_IS_ADD_EDIT_SCHED_VALID:
      return { ...state, isAddEditSchedValid: action.payload };
    case types.CHANGE_CHANNELS_FOR_CLIENT:
      return { ...state, currentChannelsForClient: action.payload };
    case types.CHANGE_SHOW_ADD_NEW_SCHEDULES_POPUP:
      return { ...state, showAddNewSchedulePopup: action.payload };
    case types.CHANGE_SHOW_DELETE_SCHEDULE_POPUP:
      return { ...state, showDeleteSchedulePopup: action.payload };
    case types.CHANGE_TIMEZONES:
      return {
        ...state,
        timezones: action.payload,
      };
    case types.CHANGE_TIMEZONE:
      let payload = action.payload;
      if (action.payload.timezoneName) {
        const tz = _getTimezoneByName(action.payload.timezoneName);
        if (tz) {
          payload.timezone = tz.offsetDisplay;
          payload.timezoneName = tz.name;
          payload.timezoneOffset = {
            hours: tz.offsetHours,
            minutes: tz.offsetMins,
          };
        }
      }
      return {
        ...state,
        timezone: payload.timezone || state.timezone,
        timezones: payload.timezones || state.timezones,
        timezoneName: payload.timezoneName || state.timezoneName,
        timezoneOffset: payload.timezoneOffset || state.timezoneOffset,
      };
    case types.CHANGE_TIMEZONE_OFFSET:
      return {
        ...state,
        timezoneOffset: action.payload,
      };
    case types.CHANGE_TIMES:
      return {
        ...state,
        times: action.payload,
      };
    case types.CHANGE_EXPANDED_VIEW:
      return {
        ...state,
        isExpandedView: action.payload,
      };

    case types.CHANGE_SELECTED_LOCATION:
      return {
        ...state,
        selectedLocation: action.payload,
      };
    case types.ADD_SCHEDULE_FROM_PLAYLIST:
      return {
        ...state,
        addScheduleFromPlaylist: action.payload,
      };
  }

  return state;
};
