import * as types from "./types";

export const changeSelectedSchedule = (payload) => {
  return {
    type: types.CHANGE_SELECTED_SCHEDULE,
    payload: payload,
  };
};

export const changeSchedules = (payload) => {
  return {
    type: types.CHANGE_SCHEDULES,
    payload: payload,
  };
};

export const changeScheduleEditMode = (payload) => {
  if (payload) {
    return {
      type: types.CHANGE_SCHEDULE_EDIT_MODE,
      payload: payload,
    };
  } else {
    return {
      type: types.CHANGE_SCHEDULE_EDIT_MODE,
      payload: payload,
    };
  }
};

export const changeShowSchedulePopup = (payload) => {
  return {
    type: types.CHANGE_SHOW_SCHEDULE_POPUP,
    payload: payload,
  };
};

export const changeEditScheduleObj = (payload) => {
  return {
    type: types.CHANGE_EDIT_SCHEDULE_OBJ,
    payload: payload,
  };
};

export const changeShowScheduleSavePopup = (payload) => {
  return {
    type: types.CHANGE_SHOW_SAVE_SCHEDULE_POPUP,
    payload: payload,
  };
};

export const changeShowScheduleLocationsPopup = (payload) => {
  return {
    type: types.CHANGE_SHOW_SCHEDULE_LOCATIONS_POPUP,
    payload: payload,
  };
};

export const changeShowScheduleDeleteConfirmationPopup = (payload) => {
  return {
    type: types.CHANGE_SHOW_SCHEDULE_DELETE_CONFIRMATION_POPUP,
    payload: payload,
  };
};

export const changeScheduleDiscardScheduleEditModePopup = (payload) => {
  return {
    type: types.CHANGE_SHOW_DISCARD_SCHEDULE_EDIT,
    payload: payload,
  };
};

export const changeIsScheduleEdited = (payload) => {
  return {
    type: types.CHANGE_IS_SCHEDULE_EDITED,
    payload: payload,
  };
};

export const changeShowScheduleOverlapsConfirmationPopup = (payload) => {
  return {
    type: types.CHANGE_SHOW_SCHEDULE_OVERLAPS_CONFIRMATION_POPUP,
    payload: payload,
  };
};

export const changeIsEditPlaylist = (payload) => {
  return {
    type: types.CHANGE_IS_EDIT_PLAYLIST,
    payload: payload,
  };
};

export const changeIsAddEditSchedValid = (payload) => {
  return {
    type: types.CHANGE_IS_ADD_EDIT_SCHED_VALID,
    payload: payload,
  };
};

export const changeChannelsForClient = (payload) => {
  return {
    type: types.CHANGE_CHANNELS_FOR_CLIENT,
    payload: payload,
  };
};

export const changeShowAddNewSchedulesPopup = (payload) => {
  if (payload) {
    return {
      type: types.CHANGE_SHOW_ADD_NEW_SCHEDULES_POPUP,
      payload: payload,
    };
  } else {
    return {
      type: types.CHANGE_SHOW_ADD_NEW_SCHEDULES_POPUP,
      payload: payload,
    };
  }
};

export const changeShowDeleteSchedulePopup = (payload) => {
  return {
    type: types.CHANGE_SHOW_DELETE_SCHEDULE_POPUP,
    payload: payload,
  };
};

// TIMEZONES
export const changeTimezones = (payload) => {
  return {
    type: types.CHANGE_TIMEZONES,
    payload: payload,
  };
};

export const changeTimezone = (payload) => {
  return {
    type: types.CHANGE_TIMEZONE,
    payload: payload,
  };
};

export const changeTimezoneOffset = (payload) => {
  return {
    type: types.CHANGE_TIMEZONE_OFFSET,
    payload: payload,
  };
};

export const changeTimes = (payload) => {
  return {
    type: types.CHANGE_TIMES,
    payload: payload,
  };
};

export const changeExpandedView = (payload) => {
  return {
    type: types.CHANGE_EXPANDED_VIEW,
    payload: payload,
  };
};

export const changeSelectedLocation = (payload) => {
  return {
    type: types.CHANGE_SELECTED_LOCATION,
    payload: payload,
  };
};

// Add schedule from playlist
export const addScheduleFromPlaylist = (payload) => {
  return {
    type: types.ADD_SCHEDULE_FROM_PLAYLIST,
    payload: payload,
  };
};
