//auth actions
export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const LOGOUT_SUCCESS = "LOGOUT_SUCCESS";
export const FREEZE_CLIENT = "FREEZE_CLIENT";
export const SHOW_FREEZE_MODAL = "SHOW_FREEZE_MODAL";
export const SHOW_MIGRATION_WARNING_BAR = "SHOW_MIGRATION_WARNING_BAR";
export const SHOW_MIGRATION_WARNING_MODAL = "SHOW_MIGRATION_WARNING_MODAL";

//login actions
export const TOGGLE_PASSWORD_VISIBILITY = "TOGGLE_PASSWORD_VISIBILITY";
export const SET_LOGIN_VALID = "SET_LOGIN_VALID";
export const SET_LOGIN_INVALID = "SET_LOGIN_INVALID";
export const SET_SHAKE_ANIMATION_ON = "SET_SHAKE_ANIMATION_ON";
export const SET_SHAKE_ANIMATION_OFF = "SET_SHAKE_ANIMATION_OFF";

//layout actions
export const SHOW_MODAL = "SHOW_MODAL";
export const HIDE_MODAL = "HIDE_MODAL";
export const CHANGE_ACTIVE_MENU_ITEM = "CHANGE_ACTIVE_MENU_ITEM";
export const CHANGE_CONTAINER_HEIGHT = "CHANGE_CONTAINER_HEIGHT";
export const SHOW_SPINNER = "SHOW_SPINNER";
export const HIDE_SPINNER = "HIDE_SPINNER";
export const SHOW_NOTIFICATION = "SHOW_NOTIFICATION";
export const HIDE_NOTIFICATION = "HIDE_NOTIFICATION";
export const CHANGE_FORGOT = "CHANGE_FORGOT";
export const CHECK_BUILD = "CHECK_BUILD";
export const SHOW_FLASH_MESSAGE = "SHOW_FLASH_MESSAGE";
export const HIDE_FLASH_MESSAGE = "HIDE_FLASH_MESSAGE";

//locations actions
export const CHANGE_LOCATIONS_SEARCH_QUERY = "CHANGE_LOCATIONS_SEARCH_QUERY";
export const CHANGE_LOCATIONS = "CHANGE_LOCATIONS";
export const CHANGE_USER_MENU = "CHANGE_USER_MENU";
export const CHANGE_MOBILE_MENU = "CHANGE_MOBILE_MENU";
export const CHANGE_SHOW_CHANGE_SCHEDULE_DIALOG = "CHANGE_SHOW_CHANGE_SCHEDULE_DIALOG";
export const CHANGE_AREA_SCHEDULE_INFO = "CHANGE_AREA_SCHEDULE_INFO";
export const CHANGE_SHOW_CONTROL_MUSIC = "CHANGE_SHOW_CONTROL_MUSIC";
export const TOGGLE_CHANGE_CHANNEL = "TOGGLE_CHANGE_CHANNEL";
export const CLOSE_CHANGE_CHANNEL = "CLOSE_CHANGE_CHANNEL";
export const TOGGLE_REPORT = "TOGGLE_REPORT";
export const CLOSE_REPORT = "CLOSE_REPORT";
export const CHANGE_REPORT_STEP = "CHANGE_REPORT_STEP";
export const CHANGE_REPORT_TYPE = "CHANGE_REPORT_TYPE";
export const CHANGE_REPORT_SUB_TYPE = "CHANGE_REPORT_SUB_TYPE";
export const CHANGE_DEVICE_STATUS = "CHANGE_DEVICE_STATUS";
export const CLOSE_DEVICE_PANELS = "CLOSE_DEVICE_PANELS";
export const SET_NOW_PLAYING = "SET_NOW_PLAYING";
export const CLOSE_OVERRIDE_CONFIRMATION_POPUP = "CLOSE_OVERRIDE_CONFIRMATION_POPUP";
export const OPEN_OVERRIDE_CONFIRMATION_POPUP = "OPEN_OVERRIDE_CONFIRMATION_POPUP";
export const UPDATE_CURRENT_OPEN_PLAYER = "UPDATE_CURRENT_OPEN_PLAYER";
export const FEEDBACK_SUCCESS_MESSAGE_IS_OPEN = "FEEDBACK_SUCCESS_MESSAGE_IS_OPEN";
export const UPDATE_LOCATION_PLAY_PAUSE_BUTTON = "UPDATE_LOCATION_PLAY_PAUSE_BUTTON";
export const UPDATE_DEVICE_STATUS = "UPDATE_DEVICE_STATUS";
export const CHANGE_SELECTED_LOCATION = "CHANGE_SELECTED_LOCATION";
export const GET_AREA_INFO = "GET_AREA_INFO";

//player actions
export const OPEN_VOLUME_CONTROL = "OPEN_VOLUME_CONTROL";
export const CLOSE_VOLUME_CONTROL = "CLOSE_VOLUME_CONTROL";
export const CHANGE_SKIP_LIST = "CHANGE_SKIP_LIST";
export const CHANGE_CHANNEL_TOGGLE = "CHANGE_CHANNEL_TOGGLE";
export const CHANGE_CHANNEL_STEP = "CHANGE_CHANNEL_STEP";
export const CHANGE_PLAYER_STATUS = "CHANGE_PLAYER_STATUS";
export const CHANGE_AVAILABLE_CHANNELS = "CHANGE_AVAILABLE_CHANNELS";
export const CHANGE_SELECTED_NEW_CHANNEL = "CHANGE_SELECTED_NEW_CHANNEL";
export const CHANGE_SELECTED_NEW_DURATION = "CHANGE_SELECTED_NEW_DURATION";
export const CHANGE_INFO_MESSAGE = "CHANGE_INFO_MESSAGE";
export const SHOW_INFO_MESSAGE = "SHOW_INFO_MESSAGE";
export const HIDE_INFO_MESSAGE = "HIDE_INFO_MESSAGE";
export const CHANGE_IS_INITIAL_UPDATE = "CHANGE_IS_INITIAL_UPDATE";
export const CHANGE_TRACK_PROGRESS = "CHANGE_TRACK_PROGRESS";
export const CHANGE_SKIP_REFRESH = "CHANGE_SKIP_REFRESH";
export const CHANGE_TERMS_OPEN = "CHANGE_TERMS_OPEN";
export const CHANGE_CURRENT_DAY_OFFSET = "CHANGE_CURRENT_DAY_OFFSET";
export const CHANGE_SCHEDULE = "CHANGE_SCHEDULE";

//schedules actions
export const CHANGE_SCHEDULES = "CHANGE_SCHEDULES";
export const CHANGE_SELECTED_SCHEDULE = "CHANGE_SELECTED_SCHEDULE";
export const CHANGE_SCHEDULE_EDIT_MODE = "CHANGE_SCHEDULE_EDIT_MODE";
export const CHANGE_SHOW_SCHEDULE_POPUP = "CHANGE_SHOW_SCHEDULE_POPUP";
export const CHANGE_EDIT_SCHEDULE_OBJ = "CHANGE_EDIT_SCHEDULE_OBJ";
export const CHANGE_SHOW_SAVE_SCHEDULE_POPUP = "CHANGE_SHOW_SAVE_SCHEDULE_POPUP";
export const CHANGE_SHOW_SCHEDULE_LOCATIONS_POPUP = "CHANGE_SHOW_SCHEDULE_LOCATIONS_POPUP";
export const CHANGE_SHOW_SCHEDULE_DELETE_CONFIRMATION_POPUP = "CHANGE_SHOW_SCHEDULE_DELETE_CONFIRMATION_POPUP";
export const CHANGE_SHOW_DISCARD_SCHEDULE_EDIT = "CHANGE_SHOW_DISCARD_SCHEDULE_EDIT";
export const CHANGE_IS_SCHEDULE_EDITED = "CHANGE_IS_SCHEDULE_EDITED";
export const CHANGE_SHOW_SCHEDULE_OVERLAPS_CONFIRMATION_POPUP = "CHANGE_SHOW_SCHEDULE_OVERLAPS_CONFIRMATION_POPUP";
export const CHANGE_IS_EDIT_PLAYLIST = "CHANGE_IS_EDIT_PLAYLIST";
export const CHANGE_IS_ADD_EDIT_SCHED_VALID = "CHANGE_IS_ADD_EDIT_SCHED_VALID";
export const CHANGE_CHANNELS_FOR_CLIENT = "CHANGE_CHANNELS_FOR_CLIENT";
export const CHANGE_SHOW_ADD_NEW_SCHEDULES_POPUP = "CHANGE_SHOW_ADD_NEW_SCHEDULES_POPUP";
export const CHANGE_SHOW_DELETE_SCHEDULE_POPUP = "CHANGE_SHOW_DELETE_SCHEDULE_POPUP";
export const UPDATE_PLAYING_TRACK = "UPDATE_PLAYING_TRACK";
export const UPDATE_PLAYER_ONLINE_STATUS = "UPDATE_PLAYER_ONLINE_STATUS";
export const CHANGE_TIMEZONES = "CHANGE_TIMEZONES";
export const CHANGE_TIMEZONE = "CHANGE_TIMEZONE";
export const CHANGE_TIMEZONE_OFFSET = "CHANGE_TIMEZONE_OFFSET";
export const CHANGE_TIMES = "CHANGE_TIMES";
export const CHANGE_EXPANDED_VIEW = "CHANGE_EXPANDED_VIEW";
export const ADD_SCHEDULE_FROM_PLAYLIST = "ADD_SCHEDULE_FROM_PLAYLIST";

//channels actions
export const CHANGE_CURRENT_CHANNEL = "CHANGE_CURRENT_CHANNEL";
export const CHANGE_CHANNELS = "CHANGE_CHANNELS";
export const CHANGE_SHOW_PREVIEW_SCREEN = "CHANGE_SHOW_PREVIEW_SCREEN";
export const CHANGE_CURRENT_CHANNEL_INDEX = "CHANGE_CURRENT_CHANNEL_INDEX";
export const CHANGE_SHOW_CURATOR_CONTACT_FORM = "CHANGE_SHOW_CURATOR_CONTACT_FORM";
export const ADD_TO_FAVOURITES = "ADD_TO_FAVOURITES";
export const REMOVE_FROM_FAVOURITES = "REMOVE_FROM_FAVOURITES";
export const GET_FAVOURITE_PLAYLISTS = "GET_FAVOURITE_PLAYLISTS";
export const GET_CHANNEL_PLAYLISTS = "GET_CHANNEL_PLAYLISTS";
export const PAUSE_ALL_PLAYLISTS = "PAUSE_ALL_PLAYLISTS";
export const PLAY_ONE_PLAYLIST = "PLAY_ONE_PLAYLIST";
export const CHECK_IS_A_FAVOURITE = "CHECK_IS_A_FAVOURITE";
export const UPDATE_PROGRESS_BAR = "UPDATE_PROGRESS_BAR";
export const RESET_PROGRESS_BAR = "RESET_PROGRESS_BAR";
export const UPDATE_PREVIEW_DETAILS = "UPDATE_PREVIEW_DETAILS";
export const CHANGE_REPORT_TRACKS = "CHANGE_REPORT_TRACKS";

// playlists actions
export const PLAYLISTS_ADD_PLAYLIST = "PLAYLISTS_ADD_PLAYLIST";
export const PLAYLISTS_ADD_PLAYLISTS = "PLAYLISTS_ADD_PLAYLISTS";
export const PLAYLISTS_ADD_SONG_TO_PLAYLIST = "PLAYLISTS_ADD_SONG_TO_PLAYLIST";
export const PLAYLISTS_LOAD_URL = "PLAYLISTS_LOAD_URL";
export const PLAYLISTS_PLAY_PREVIEW = "PLAYLISTS_PLAY_PREVIEW";
export const PLAYLISTS_PAUSE_PREVIEW = "PLAYLISTS_PAUSE_PREVIEW";
export const PLAYLISTS_SET_PREVIEWED = "PLAYLISTS_SET_PREVIEWED";
export const PLAYLISTS_RESET_PREVIEWED = "PLAYLISTS_RESET_PREVIEWED";
export const PLAYLISTS_ELAPSE_TRACK_PROGRESS = "PLAYLISTS_ELAPSE_TRACK_PROGRESS";
export const PLAYLIST_UPDATE_PREVIEW_NOW = "PLAYLIST_UPDATE_PREVIEW_NOW";
export const PLAYLIST_UPDATE_PREVIEW_NEXT = "PLAYLIST_UPDATE_PREVIEW_NEXT";
export const PLAYLIST_SET_SONG_AS_NOW_PLAYING = "PLAYLIST_SET_SONG_AS_NOW_PLAYING";
export const PLAYLIST_SET_SONG_AS_NOT_PLAYING = "PLAYLIST_SET_SONG_AS_NOT_PLAYING";
export const PLAYLIST_SET_PLAYLIST_AS_NOW_PLAYING = "PLAYLIST_SET_PLAYLIST_AS_NOW_PLAYING";
export const PLAYLIST_SET_PLAYLIST_AS_NOT_PLAYING = "PLAYLIST_SET_PLAYLIST_AS_NOT_PLAYING";
export const PLAYLIST_CLEAN_QUEUE = "PLAYLIST_CLEAN_QUEUE";
export const PLAYLIST_CLEAR_PREVIEW_QUEUE = "PLAYLIST_CLEAR_PREVIEW_QUEUE";
export const PLAYLISTS_RESET_ELAPSE_TRACK_PROGRESS = "PLAYLISTS_RESET_ELAPSE_TRACK_PROGRESS";
export const PLAYLIST_UPDATE_CURRENT = "PLAYLIST_UPDATE_CURRENT";
export const LISTEN_IN_PLAYLIST = "LISTEN_IN_PLAYLIST";

// INSIGHTS ACTIONS
export const CHANGE_IS_ADMIN_APPROVAL_NEEDED = "CHANGE_IS_ADMIN_APPROVAL_NEEDED";
export const CHANGE_IS_MEDIA_PLAYING = "CHANGE_IS_MEDIA_PLAYING";

// curation actions
export const UPDATE_CURATION_FIELD = "UPDATE_CURATION_FIELD";
export const CURATION_SENT = "CURATION_SENT";
export const CURATION_SAVING_ERROR = "CURATION_SAVING_ERROR";
export const CLOSE_CURATION_MODALS = "CLOSE_CURATION_MODALS";
export const CURATION_TOGGLE_AFFECTED_LOCATION = "CURATION_TOGGLE_AFFECTED_LOCATION";
export const CLEAR_FIELDS = "CLEAR_FIELDS";
export const CHANGE_SUBMITTED_CURATION_REQUESTS = "CHANGE_SUBMITTED_CURATION_REQUESTS";
export const DESELECT_ALL_LOCATIONS = "DESELECT_ALL_LOCATIONS";
export const SELECT_LOCATIONS = "SELECT_LOCATIONS";

// feedback actions
export const CHANGE_FEEDBACK_TRACK = "CHANGE_FEEDBACK_TRACK";
export const CHANGE_FEEDBACK_TYPE = "CHANGE_FEEDBACK_TYPE";
export const CHANGE_FEEDBACK_MESSAGE = "CHANGE_FEEDBACK_MESSAGE";
export const CHANGE_FEEDBACK_DONE = "CHANGE_FEEDBACK_DONE";
export const CLEAR_FEEDBACK = "CLEAR_FEEDBACK";
