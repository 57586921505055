import React, { Component, Suspense } from "react";
import { TransitionGroup, CSSTransition } from "react-transition-group";
import { Link, Route, withRouter, Switch, Redirect } from "react-router-dom";
import { connect } from "react-redux";
import * as satismeter from "satismeter-loader";
import CookieBanner from "react-cookie-banner";

import { VERSION_CHECK_INTERVAL, SATISMETER_WRITE_KEY } from "@config";

import "@stylesheets/main.scss";

import store from "@app/store";

import * as authApi from "@api/auth-api";
import * as layoutApi from "@api/layout-api";
import * as locationsApi from "@api/locations-api";
import * as schedulesApi from "@api/schedules-api";
import * as channelsApi from "@api/channels-api";
import * as playlistsApi from "@api/playlists-api";
import * as curationApi from "@api/curation-api";

import Loading from "@partials/loading";
import LoadingTypewriter from "@partials/loading-typewriter";

import SideMenu from "./side-menu";
import WarningBar from "./warning-bar";
const NewBuildTicker = React.lazy(() => import("./new-build-ticker"));
const NotificationBubble = React.lazy(() => import("./notification"));
const LocationsMain = React.lazy(() => import("@components/locations/locations-main"));
const AddLocation = React.lazy(() => import("@components/locations/add-location"));
const SchedulesMain = React.lazy(() => import("@components/schedules/schedules-main"));
const PlaylistsMain = React.lazy(() => import("@components/playlists/playlists-main"));
const CurationRequest = React.lazy(() => import("@components/playlists/curation-request/curation-request"));
const Insights = React.lazy(() => import("@components/insights/insights"));
const FlashMessage = React.lazy(() => import("@partials/FlashMessage/FlashMessage"));

import { changeActiveMenuItem, changeUserMenu, changeMobileMenu, hideNotification } from "@actions/layout-actions";

import { changeTimezone } from "@actions/schedules-actions";

import * as gaAnalytics from "@common/gaAnalyticsHelper";

class MainLayout extends Component {
  get pageTitle() {
    const title = this.props.location.pathname.split("/")[1];
    return `${title.charAt(0).toUpperCase()}${title.slice(1)}`;
  }

  constructor(props) {
    super(props);

    this._handleLogout = this._handleLogout.bind(this);
    this._handleChangeActiveMenuItem = this._handleChangeActiveMenuItem.bind(this);
    this._handleUserMenuClick = this._handleUserMenuClick.bind(this);
    this._handleMobileMenuToggle = this._handleMobileMenuToggle.bind(this);
    this._handleHideNotification = this._handleHideNotification.bind(this);
    this.handleClickCurationButton = this.handleClickCurationButton.bind(this);
  }

  componentWillMount() {
    /*
     * we need to check if the user tries to directly load a route different
     * from the index one to overwrite the "default" selected page
     */

    window.addEventListener("storage", (e) => {
      if (e.key === "AMBIE_USER") {
        if (e.newValue !== e.oldValue && e.oldValue !== null) {
          authApi.logout();
        }
        if (e.oldValue === null) {
          this.props.history.push("/locations");
        }
      }
    });

    let currentPage =
      this.props.location.pathname.split("/").length > 1 ? this.props.location.pathname.split("/")[1] : "/";

    if (currentPage !== this.props.activeMenuItem) {
      store.dispatch(changeActiveMenuItem(currentPage));
    }
  }

  componentDidMount() {
    layoutApi.getLatestVersion();
    this._addInterval();
    if (this.props.userInfo.clientId) {
      this.initSatismeter();
    }
  }

  initSatismeter = () => {
    try {
      const details = {
        writeKey: SATISMETER_WRITE_KEY,
        userId: this.props.userInfo.clientId,
        traits: {
          createdAt: this.props.userInfo.created,
          username: this.props.userInfo.userName,
          businessName: this.props.userInfo.businessName,
        },
      };
      satismeter(details);
    } catch (e) {}
  };

  _addInterval() {
    window.clearInterval(this.checkBuild);
    this.checkBuild = window.setInterval(() => {
      layoutApi.getLatestVersion();
      locationsApi.getLocations(true);
      schedulesApi.getSchedules();
      channelsApi.getFavourites();
      channelsApi.getChannels();
      playlistsApi.getMyPlaylists(this.props.userToken);
      curationApi.getCurationRequests(this.props.clientId);
    }, VERSION_CHECK_INTERVAL * 60 * 60 * 1000);
  }

  _handleChangeActiveMenuItem(newItem, source) {
    if (
      newItem === "schedules" &&
      this.props.locations &&
      this.props.locations.length >= 1 &&
      this.props.locations[0].timezone &&
      this.props.locations[0].timezone.name &&
      this.props.timezoneName.toLowerCase().replace("_", "") !==
        this.props.locations[0].timezone.name.toLowerCase().replace("_", "")
    ) {
      if (!this.props.locations.some((l) => l.timezone && l.timezone.name === this.props.timezoneName)) {
        store.dispatch(
          changeTimezone({
            timezoneName: this.props.locations[0].timezone.name,
          })
        );
      }
    }
    store.dispatch(changeActiveMenuItem(newItem, source));
  }

  _handleLogout(e) {
    gaAnalytics.trackEvent("Logout", "logging out");
    e.preventDefault();
    store.dispatch(changeUserMenu(false));
    authApi.logout();
    mixpanel.reset();
  }

  _handleUserMenuClick(e) {
    e.preventDefault();
    store.dispatch(changeUserMenu(!this.props.isUserMenuOpen));
  }

  _handleMobileMenuToggle() {
    store.dispatch(changeMobileMenu(!this.props.isMobileMenuOpen));
  }

  _handleHideNotification() {
    store.dispatch(hideNotification());
  }

  _handleReload() {
    location.reload();
  }

  handleClickCurationButton() {
    this.props.history.push("/curation");
  }

  generateWarningMessage() {
    return (
      <p className="warning-bar">
        We're upgrading your Ambie account. Please contact
        <a
          href="https://meetings-eu1.hubspot.com/mary-sayers?utm_source=hs_email&utm_medium=email&_hsenc=p2ANqtz-819xfCjXttayPoX8LIcKIS3biO2v3vfMxdJ8eEmHyNtpMailHlJOnhjI7JcIAQKlFNlBNi"
          target="_blank"
          rel="noopener noreferrer"
        >
          Mary
        </a>
        or
        <a
          href="https://meetings-eu1.hubspot.com/marissa-tavernier?utm_source=hs_email&utm_medium=email&_hsenc=p2ANqtz-819xfCjXttayPoX8LIcKIS3biO2v3vfMxdJ8eEmHyNtpMailHlJOnhjI7JcIAQKlFNlBNi"
          target="_blank"
          rel="noopener noreferrer"
        >
          Marissa
        </a>
        to avoid disruption to your service.
      </p>
    );
  }

  render() {
    return (
      <>
        <WarningBar isVisible={this.props.showMigrationBar}>{this.generateWarningMessage()}</WarningBar>
        <div className={`default-main-background${this.props.showMigrationBar ? " warning-margin-top" : ""}`}>
          <SideMenu
            handleLogout={this._handleLogout}
            businessName={this.props.businessName}
            userId={parseInt(this.props.userId, 10)}
            userToken={this.props.userToken}
            name={this.props.userName}
            username={this.props.userInfo.username}
          />

          <React.Suspense fallback={<Loading />}>
            <FlashMessage
              duration={this.props.flashMessage.duration || 2000}
              status={this.props.flashMessage.status}
              show={this.props.flashMessage.show}
              message={this.props.flashMessage.message}
              subMessage={this.props.flashMessage.subMessage}
              childElement={this.props.flashMessage.childElement}
              onClick={this.props.flashMessage.onClick}
              backdrop={this.props.flashMessage.backdrop}
              showClose={this.props.flashMessage.showClose}
            />

            <NotificationBubble
              message={this.props.notification.message}
              // message={"I'm a boring notification bubble"}
              adjustTopDistance={this.props.showMigrationBar}
              isError={this.props.notification.isError}
              isVisible={this.props.notification.isVisible}
              // isVisible={true}
              isSticky={this.props.notification.isSticky}
              hideAfter={this.props.notification.hideAfter}
              handleHideNotification={this._handleHideNotification}
            />

            <main
              id={`main-content ${this.props.locations.length > 1 ? "multiple-miniplayer-padding" : ""}`}
              className="main-content"
            >
              <TransitionGroup>
                <CSSTransition timeout={200} unmountOnExit classNames="slide-up">
                  <Switch>
                    <Route path="/locations/new" component={AddLocation} />
                    <Route path="/locations" component={LocationsMain} />
                    <Route path="/schedules" component={SchedulesMain} />
                    <Route
                      path="/curation/:type(new|refresh|other-request|add-remove-track)"
                      component={CurationRequest}
                    />
                    <Route path="/curation" component={PlaylistsMain} />
                    <Route path="/insights" component={Insights} />
                    <Redirect from="/" to="/locations" />
                    <Route exact path="*" render={() => <Redirect to="/locations" />} />
                  </Switch>
                </CSSTransition>
              </TransitionGroup>
              {this.props.children}
            </main>
          </React.Suspense>

          <CookieBanner
            message="We use cookies to help give you the best experience. By continuing to browse this site, you agree to ambie.fm/privacy"
            onAccept={() => {}}
            cookie="cookie-policy"
            buttonMessage="I Understand"
          />

          <LoadingTypewriter show={this.props.showSpinner} />
        </div>
      </>
    );
  }
}

const storeToProps = (store) => {
  return {
    menuItems: store.layoutState.menuItems,
    activeMenuItem: store.layoutState.activeMenuItem,
    userInfo: store.authState.userInfo || {},
    userToken: store.authState.userInfo ? store.authState.userInfo.userToken : "",
    showMigrationBar: store.authState.showMigrationBar,
    userName:
      typeof store.authState.userInfo.userName !== "undefined"
        ? store.authState.userInfo.userName
        : store.authState.userInfo.businessName,
    userId: store.authState.userInfo.userId || store.authState.userInfo.clientId || "",
    businessName: store.authState.userInfo ? store.authState.userInfo.businessName : "",
    businessImage: store.authState.userInfo ? store.authState.userInfo.businessImage : "",
    isUserMenuOpen: store.layoutState.isUserMenuOpen,
    isMobileMenuOpen: store.layoutState.isMobileMenuOpen,
    showSpinner: store.layoutState.showSpinner,
    notification: store.layoutState.notification,
    flashMessage: store.layoutState.flashMessage,
    showNewBuildTicker: store.layoutState.showNewBuildTicker,
    locations: store.locationsState.locations,
    timezone: store.schedulesState.timezone,
    timezoneName: store.schedulesState.timezoneName,
    clientId: store.authState.userInfo.clientId,
  };
};

export default withRouter(connect(storeToProps)(MainLayout));
