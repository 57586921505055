import * as types from "./types";
import * as mixPanelAnalytics from "../common/mixPanelHelper";

export function loginSuccess(data) {
  //debugger;

  mixPanelAnalytics.loginEvent();

  return {
    type: types.LOGIN_SUCCESS,
    payload: data,
    meta: {
      MP_Action: "Login",
      MP_Payload: {
        Button: "Login",
      },
      MP_Username: data.userName,
      MP_Businessname: data.businessName,
    },
  };
}

export function logoutSuccess() {
  return {
    type: types.LOGOUT_SUCCESS,
    meta: {
      MP_Action: "Logout",
      MP_Payload: {
        Button: "Logout",
      },
    },
  };
}

export function freezeClient(payload) {
  return {
    type: types.FREEZE_CLIENT,
    payload,
  };
}

export function showFreezeModal(payload) {
  return {
    type: types.SHOW_FREEZE_MODAL,
    payload,
  };
}

export function showMigrationWarningBar(payload) {
  return {
    type: types.SHOW_MIGRATION_WARNING_BAR,
    payload,
  };
}

export function showMigrationWarningModal(payload) {
  return {
    type: types.SHOW_MIGRATION_WARNING_MODAL,
    payload,
  };
}
