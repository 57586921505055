import { combineReducers } from "redux";

// Reducers
import authReducer from "./auth-reducer";
import loginReducer from "./login-reducer";
import layoutReducer from "./layout-reducer";
import locationsReducer from "./locations-reducer";
import playerReducer from "./player-reducer";
import schedulesReducer from "./schedules-reducer";
import channelsReducer from "./channels-reducer";
import playlistsReducer from "./playlists-reducer";
import insightsReducer from "./insights-reducer";
import curationReducer from "./curation-reducer";
import feedbackReducer from "./feedback-reducer";

// Combine Reducers
let reducers = combineReducers({
  authState: authReducer,
  loginState: loginReducer,
  layoutState: layoutReducer,
  locationsState: locationsReducer,
  playerState: playerReducer,
  schedulesState: schedulesReducer,
  channelsState: channelsReducer,
  playlistsState: playlistsReducer,
  insightsState: insightsReducer,
  feedbackState: feedbackReducer,
  curationState: curationReducer,
});

export default reducers;
