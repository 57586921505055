import * as types from "@actions/types";

const initalState = {
  isAdminApprovalNeeded: false,
  isMediaPlaying: false,
};

const insightsReducer = (state = initalState, action) => {
  switch (action.type) {
    case types.CHANGE_IS_ADMIN_APPROVAL_NEEDED: {
      return {
        ...state,
        isAdminApprovalNeeded: action.payload,
      };
    }
    case types.CHANGE_IS_MEDIA_PLAYING: {
      return {
        ...state,
        isMediaPlaying: action.payload,
      };
    }
    default: {
      return state;
    }
  }
};

export default insightsReducer;
