import React from "react";

const InsightsSvg = (props) => (
  <svg
    version="1.1"
    id="Capa_1"
    x="0px"
    y="0px"
    viewBox="0 0 512 512"
    fill={props.fill || "none"}
    stroke={props.stroke || "none"}
    strokeWidth={props.strokeWidth || 1}
  >
    <g>
      <g>
        <g>
          <path
            d="M45.33,283.02l-39.696,40.92c-7.675,7.913-7.484,20.55,0.429,28.226c3.878,3.763,8.889,5.634,13.897,5.634
            c5.21,0,10.416-2.028,14.329-6.062l39.696-40.92c7.675-7.913,7.484-20.55-0.429-28.226
            C65.643,274.915,53.006,275.107,45.33,283.02z"
          />
          <path
            d="M137.731,295.92c-11.023,0-19.961,8.937-19.961,19.961v175.658c0,11.023,8.937,19.961,19.961,19.961h0.001
            c11.023,0,19.961-8.937,19.961-19.961l-0.002-175.659C157.691,304.857,148.755,295.92,137.731,295.92z"
          />
          <path
            d="M215.579,252.007c-11.023,0-19.961,8.937-19.961,19.961v219.571c0,11.023,8.937,19.961,19.961,19.961
            s19.961-8.937,19.961-19.961V271.967C235.539,260.944,226.603,252.007,215.579,252.007z"
          />
          <path
            d="M452.116,0.498h-59.883c-11.024,0-19.961,8.937-19.961,19.961s8.937,19.961,19.961,19.961h52.61L298.273,186.959
            c-3.932,3.933-9.078,6.17-14.167,6.17c-0.107,0-0.216-0.001-0.323-0.003c-4.901-0.092-9.91-2.339-13.738-6.168l-23.468-23.468
            c-11.489-11.489-26.741-18.149-41.848-18.273c-0.147-0.001-0.293-0.001-0.439-0.001c-15.636,0-31.25,6.835-42.919,18.799
            l-42.776,42.969c-7.778,7.813-7.75,20.451,0.063,28.229c3.894,3.877,8.988,5.815,14.082,5.815c5.123,0,10.246-1.961,14.146-5.879
            l42.854-43.046c0.059-0.058,0.116-0.117,0.174-0.176c4.168-4.293,9.6-6.839,14.488-6.79c4.651,0.038,9.865,2.498,13.948,6.581
            l23.468,23.468c11.226,11.227,25.866,17.568,41.223,17.854c15.969,0.3,31.822-6.209,43.463-17.852L472.077,69.644v49.622
            c0,11.024,8.937,19.961,19.961,19.961s19.961-8.937,19.961-19.961V60.381C511.999,27.362,485.136,0.498,452.116,0.498z"
          />
          <path
            d="M58.952,385.746c-11.05-0.038-20.029,8.91-20.029,19.961v85.832c0,11.023,8.937,19.961,19.961,19.961h0.001
            c11.024,0,19.961-8.937,19.961-19.961v-85.832C78.845,394.709,69.951,385.783,58.952,385.746z"
          />
          <path
            d="M451.118,171.165c-11.024,0-19.961,8.937-19.961,19.961V491.54c0,11.023,8.937,19.961,19.961,19.961h0.001
            c11.023,0,19.961-8.937,19.961-19.961V191.126C471.079,180.101,462.143,171.165,451.118,171.165z"
          />
          <path
            d="M293.426,276.958c-11.024,0-19.961,8.937-19.961,19.961v194.62c0,11.023,8.937,19.961,19.961,19.961h0.001
            c11.023,0,19.961-8.937,19.961-19.961v-194.62C313.387,285.895,304.451,276.958,293.426,276.958z"
          />
          <path
            d="M372.2,239.032c-10.996,0.04-19.888,8.966-19.888,19.961V491.54c0,11.023,8.937,19.961,19.961,19.961h0.001
            c11.023,0,19.961-8.937,19.961-19.961V258.993C392.233,247.94,383.252,238.991,372.2,239.032z"
          />
        </g>
      </g>
    </g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
  </svg>
);

export default InsightsSvg;
