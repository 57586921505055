import GoogleAnalytics from "react-ga";

import { GOOGLE_ANALYTICS_ID } from "@config";
import { track } from "@common/mixPanelHelper";

export const init = (id = GOOGLE_ANALYTICS_ID) => {
  GoogleAnalytics.initialize(id, {
    debug: USE_REDUX_LOGGER === "true",
  });
};

export const getUsername = () => {
  const ambieObject = JSON.parse(localStorage.getItem("AMBIE_USER"));
  return ambieObject ? ambieObject.userName || ambieObject.businessName : "NOT LOGGED IN";
};

export const pageView = (path) => {
  GoogleAnalytics.pageview(path);
};

export const trackEvent = (category, action, mixpanelEvent) => {
  GoogleAnalytics.event({
    category,
    action,
    label: getUsername(),
  });
  if (mixpanelEvent) {
    track(category, mixpanelEvent);
  }
};

export const trackPage = (page, options) => {
  GoogleAnalytics.set({
    page,
    ...options,
  });
  GoogleAnalytics.pageview(page);
};
