import {
  LOCAL_STORAGE_USER,
  LOCAL_STORAGE_USER_TOKEN,
  LOCAL_STORAGE_USER_REFRESH_TOKEN,
  LOCAL_STORAGE_FROZEN_CLIENT,
  LOCAL_STORAGE_FREEZE_MODAL,
} from "../config";

/**
 * Store  local storage for user record
 */
export function storeUser(user) {
  try {
    localStorage.setItem(LOCAL_STORAGE_USER, JSON.stringify(user));
  } catch (error) {
    throw new Error(error);
  }
}

/**
 * Get  local storage for user record
 */
export function getUser() {
  try {
    const jsonValue = localStorage.getItem(LOCAL_STORAGE_USER);
    return jsonValue != null ? JSON.parse(jsonValue) : null;
  } catch (error) {
    throw new Error(error);
  }
}

/**
 * Store  local storage for freeze client
 */
export function storeFrozenClient(client) {
  try {
    localStorage.setItem(LOCAL_STORAGE_FROZEN_CLIENT, JSON.stringify(client));
  } catch (error) {
    throw new Error(error);
  }
}

/**
 * Get  local storage for freeze client
 */
export function getFreezeClient() {
  try {
    const jsonValue = localStorage.getItem(LOCAL_STORAGE_FROZEN_CLIENT);
    return jsonValue != null ? JSON.parse(jsonValue) : null;
  } catch (error) {
    throw new Error(error);
  }
}

/**
 * Store  local storage for user token
 */
export function storeToken(token) {
  try {
    localStorage.setItem(LOCAL_STORAGE_USER_TOKEN, token);
  } catch (error) {
    throw new Error(error);
  }
}

/**
 * Get  local storage for user token
 */
export function getToken() {
  try {
    const value = localStorage.getItem(LOCAL_STORAGE_USER_TOKEN);
    if (value !== null) {
      return value;
    }
  } catch (error) {
    throw new Error(error);
  }
}

/**
 * Store  local storage for  refreshuser token
 */
export function storeRefreshToken(token) {
  try {
    localStorage.setItem(LOCAL_STORAGE_USER_REFRESH_TOKEN, token);
  } catch (error) {
    throw new Error(error);
  }
}

/**
 * Get  local storage for user refresh token
 */
export function getRefreshToken() {
  try {
    const value = localStorage.getItem(LOCAL_STORAGE_USER_REFRESH_TOKEN);
    if (value !== null) {
      return value;
    }
  } catch (error) {
    throw new Error(error);
  }
}

/**
 * Store local storage for frozen modal
 */
export function storeFreezeModal(freezeModal) {
  try {
    localStorage.setItem(LOCAL_STORAGE_FREEZE_MODAL, freezeModal);
  } catch (error) {
    throw new Error(error);
  }
}

/**
 * Get local storage for frozen modal
 */
export function getFreezeModal() {
  try {
    const value = localStorage.getItem(LOCAL_STORAGE_FREEZE_MODAL);
    if (value !== null) {
      return value;
    }
  } catch (error) {
    throw new Error(error);
  }
}

// /**
//  * Store  local storage value for onboarding screen
//  */
// export function storeOnboardingScreenValue(value) {
//   try {
//     localStorage.setItem(LOCAL_STORAGE_ONBOARDING_SCREEN_VALUE, value);
//   } catch (error) {
//     throw new Error(error);
//   }
// }

// /**
//  * Get  local storage value for onboarding screen
//  */
// export function getOnboardingScreenValue() {
//   try {
//     const value = localStorage.getItem(LOCAL_STORAGE_ONBOARDING_SCREEN_VALUE);
//     if (value !== null) {
//       return value;
//     }
//   } catch (error) {
//     throw new Error(error);
//   }
// }
