import * as React from "react";

import CookieBanner from "react-cookie-banner";

import Footer from "../layout/partials/footer";

const LoginSplitContainer = (props) => (
  <div
    id="login-wrapper"
    style={{ height: "100vh" }}
    className="login-main-background full-width  col-sm-12 no-padding  col-xs-12 no-padding"
  >
    <div className="login-left">
      <div className="login-message flex-center-center flex-column">
        <img className="side-menu-graphic" color="white" src="/images/illustrations/login.png" alt="Ambie" />
        <img className="side-menu-logo" color="white" src="/images/logo/ambie-white.svg" alt="Ambie" />
        <h1>dashboard</h1>
      </div>
    </div>
    <div className="login-right">
      <div className="login-container">{props.children}</div>
    </div>
    {props.showFooter && <Footer />}
    <CookieBanner
      message="We use cookies to help give you the best experience. By continuing to browse this site, you agree to ambie.fm/privacy"
      onAccept={() => {}}
      cookie="cookie-policy"
      buttonMessage="I Understand"
    />
  </div>
);

export default LoginSplitContainer;
