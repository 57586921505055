import React from "react";

const CurationSvg = (props) => (
  <svg
    version="1.1"
    id="Capa_1"
    x="0px"
    y="0px"
    fill={props.fill || "none"}
    stroke={props.stroke || "none"}
    strokeWidth={props.strokeWidth || 1}
    viewBox="52 10 82 48"
  >
    <g>
      <g>
        <path
          d="M72.011,26.328c0-1.48-1.199-2.68-2.68-2.68h-8.826c-1.48,0-2.68,1.199-2.68,2.68c0,1.479,1.199,2.68,2.68,2.68h8.826
          C70.812,29.008,72.011,27.808,72.011,26.328z"
        />
      </g>
    </g>
    <g>
      <g>
        <path
          d="M82.746,31.688v-2.68h40.999c1.48,0,2.68-1.2,2.68-2.68c0-1.48-1.199-2.68-2.68-2.68H82.746v-2.68
          c0-1.48-1.2-2.68-2.68-2.68c-1.48,0-2.68,1.199-2.68,2.68v10.719c0,1.479,1.199,2.68,2.68,2.68
          C81.546,34.367,82.746,33.167,82.746,31.688z"
        />
      </g>
    </g>
    <g>
      <g>
        <path
          d="M107.115,8.107c0-1.48-1.2-2.68-2.68-2.68H60.505c-1.48,0-2.68,1.199-2.68,2.68c0,1.479,1.199,2.68,2.68,2.68h43.931
          C105.915,10.786,107.115,9.586,107.115,8.107z"
        />
      </g>
    </g>
    <g>
      <g>
        <path
          d="M117.817,13.466v-2.68h5.928c1.48,0,2.68-1.2,2.68-2.68c0-1.48-1.199-2.68-2.68-2.68h-5.928v-2.68
          c0-1.48-1.2-2.68-2.68-2.68c-1.48,0-2.68,1.199-2.68,2.68v10.719c0,1.479,1.199,2.68,2.68,2.68
          C116.617,16.146,117.817,14.946,117.817,13.466z"
        />
      </g>
    </g>
    <g>
      <g>
        <path
          d="M103.491,60.493c0-1.479-1.2-2.68-2.68-2.68H60.505c-1.48,0-2.68,1.2-2.68,2.68c0,1.48,1.199,2.68,2.68,2.68h40.307
          C102.291,63.173,103.491,61.974,103.491,60.493z"
        />
      </g>
    </g>
    <g>
      <g>
        <path
          d="M114.216,65.853v-2.68h9.529c1.48,0,2.68-1.199,2.68-2.68c0-1.479-1.199-2.68-2.68-2.68h-9.529v-2.68
          c0-1.479-1.199-2.68-2.68-2.68c-1.479,0-2.68,1.2-2.68,2.68v10.719c0,1.48,1.2,2.68,2.68,2.68
          C113.017,68.532,114.216,67.333,114.216,65.853z"
        />
      </g>
    </g>
    <g>
      <g>
        <path
          d="M83.802,43.075c0-1.479-1.2-2.68-2.68-2.68H60.505c-1.48,0-2.68,1.2-2.68,2.68c0,1.48,1.199,2.68,2.68,2.68h20.617
          C82.602,45.755,83.802,44.556,83.802,43.075z"
        />
      </g>
    </g>
    <g>
      <g>
        <path
          d="M94.504,48.435v-2.68h29.241c1.48,0,2.68-1.199,2.68-2.68c0-1.479-1.199-2.68-2.68-2.68H94.504v-2.68
          c0-1.479-1.199-2.68-2.68-2.68c-1.479,0-2.68,1.2-2.68,2.68v10.719c0,1.48,1.2,2.68,2.68,2.68
          C93.305,51.115,94.504,49.915,94.504,48.435z"
        />
      </g>
    </g>
  </svg>
);

export default CurationSvg;
