import mixpanel from "mixpanel-browser";

import { MIXPANEL_ID } from "@config";

export const init = (id = MIXPANEL_ID) => {
  mixpanel.init(id, { debug: true, track_pageview: false, persistence: 'localStorage', api_host: "https://api-eu.mixpanel.com" });
  return mixpanel;
};

export const track = (trackEvent, mixpanelEvent) => {
  if (mixpanelEvent) {
    mixpanel.track(trackEvent, mixpanelEvent);
  } else {
    mixpanel.track(trackEvent);
  }
};

export const getDevice = () => {
  const ambieObject3 = JSON.parse(localStorage.getItem("AMBIE_USER"));
  return ambieObject3.device && ambieObject3.device.push_token ? ambieObject3.device.push_token : "";
};

export const getUser = () => {
  const ambieObject = JSON.parse(localStorage.getItem("AMBIE_USER"));
  return ambieObject;
};

export const loginEvent = () => {
  const device = getDevice();
  const user = getUser();

  mixpanel.identify(user.userId);
  mixpanel.people.set({
    $client_id: user.clientId,
    $client_name: user.businessName,
    $user_id: user.userId,
    $username: user.username,
    $name: user.userName,
    $email: user.email,
    $user_type: user.userType,
    $device: device,
    $last_login: new Date(),
  });

  mixpanel.register({
    client_id: user.clientId,
    user_id: user.userId,
    client_name: user.businessName,
    username: user.username,
    email: user.email,
    user_type: user.userType,
    product: "Dashboard"
  });
};
