import * as types from "./../actions/types";
import { getNowPlaying } from "../api/locations-api";
//import MQTTClient from '../components/AWSIotClient';

const locationsState = {
  locations: [],
  searchQuery: "",
  showChangeScheduleDialog: false,
  areaScheduleInfo: {
    areaName: "",
    areaId: "",
    scheduleName: "",
    scheduleId: "",
  },
  areaInfo: {
    areaId: 0,
    areaName: "",
    tlgVersion: "",
  },
  showControlMusic: false,
  changeChannelIsOpen: false,
  showOverrideConfirmationPopup: false,
  isConfirmationBox: true,
  currentOpenPlayer: 0,
  reportIsOpen: false,
  reportStepValue: 1,
  reportType: "feedback",
  reportSubType: "suggestion",
  reportTracks: {},
  //ws_connection : false,
  feedbackSuccessMessageIsOpen: false,
  playPauseButtonStates: [], // {deviceId: "c-9999", state: "play"}
};

const locationsReducer = (state = locationsState, action) => {
  switch (action.type) {
    case types.UPDATE_DEVICE_STATUS:
      let myLocalLocations = Object.assign([], state.locations);

      myLocalLocations.forEach((L) => {
        L.areas.forEach((A) => {
          if (A.device.serial === action.payload.deviceId) {
            A.device.status = action.payload.status;
            A.device.volume = action.payload.volume;
            A.device.dontUpdate = 2;
            // if (A.device.status === 'pause' && action.payload.status === 'play') { // was used because if the player was paused for a long time, the progress would be wrong
            //   A.device.current_track.start = new Date().getTime();
            //   A.device.last_played = new Date().getTime();
            // }
          }
        });
      });

      return Object.assign({}, state, {
        locations: myLocalLocations,
      });

      break;

    case types.UPDATE_LOCATION_PLAY_PAUSE_BUTTON:
      let newButtonStates = state.playPauseButtonStates.map((button) => {
        if (button.deviceId === action.payload.deviceId) {
          return Object.assign({}, button, { state: action.payload.state });
        } else {
          return button;
        }
      });

      return Object.assign({}, state, {
        playPauseButtonStates: newButtonStates,
      });

      break;

    case types.SET_NOW_PLAYING:
      let deviceId = action.payload[0];
      let nowPlaying = action.payload[1];

      let remapped = state.locations.map((location) => {
        location.areas.map((area) => {
          if (area.device.name === deviceId) {
            area.device.nowPlaying = nowPlaying;
          }
          return area;
        });
        return location;
      });

      return Object.assign({}, state, {
        locations: remapped,
      });

      break;

    case types.CLOSE_DEVICE_PANELS:
      break;

    case types.CHANGE_DEVICE_STATUS:
      return Object.assign({}, state, {
        playerStatus: action.payload,
      });
      break;

    case types.CHANGE_LOCATIONS_SEARCH_QUERY:
      return Object.assign({}, state, {
        searchQuery: action.payload,
      });
      break;

    case types.CHANGE_LOCATIONS: {
      return {
        ...state,
        locations: action.payload,
      };
    }

    case types.CHANGE_SHOW_CHANGE_SCHEDULE_DIALOG:
      return Object.assign({}, state, {
        showChangeScheduleDialog: action.payload,
      });
      break;

    case types.CHANGE_AREA_SCHEDULE_INFO:
      return Object.assign({}, state, {
        areaScheduleInfo: action.payload,
      });
      break;

    case types.GET_AREA_INFO:
      return Object.assign({}, state, {
        areaInfo: action.payload,
      });
      break;

    case types.CHANGE_SHOW_CONTROL_MUSIC:
      return Object.assign({}, state, {
        showControlMusic: action.payload,
      });
      break;

    case types.CLOSE_OVERRIDE_CONFIRMATION_POPUP:
      return Object.assign({}, state, {
        showOverrideConfirmationPopup: false,
      });
      break;

    case types.OPEN_OVERRIDE_CONFIRMATION_POPUP:
      return Object.assign({}, state, {
        showOverrideConfirmationPopup: true,
        isConfirmationBox: action.payload,
      });
      break;

    case types.TOGGLE_CHANGE_CHANNEL:
      return Object.assign({}, state, {
        changeChannelIsOpen: action.payload,
      });
      break;

    case types.UPDATE_CURRENT_OPEN_PLAYER:
      return Object.assign({}, state, {
        currentOpenPlayer: action.payload,
      });
      break;

    case types.CLOSE_CHANGE_CHANNEL:
      return Object.assign({}, state, {
        changeChannelIsOpen: false,
      });
      break;

    case types.TOGGLE_REPORT:
      return Object.assign({}, state, {
        reportIsOpen: !state.reportIsOpen,
      });
      break;

    case types.CLOSE_REPORT:
      return Object.assign({}, state, {
        reportIsOpen: false,
      });
      break;

    case types.CHANGE_REPORT_STEP:
      return Object.assign({}, state, {
        reportStepValue: action.payload || 1,
      });
      break;

    case types.CHANGE_REPORT_TYPE:
      return Object.assign({}, state, {
        reportType: action.payload || "feedback",
      });
      break;

    case types.CHANGE_REPORT_TRACKS:
      return Object.assign({}, state, {
        reportTracks: {
          current_track: action.payload.current_track || {},
          previous_track: action.payload.previous_track || {},
          location: action.payload.location || {},
        },
      });
      break;

    case types.CHANGE_REPORT_SUB_TYPE:
      return Object.assign({}, state, {
        reportSubType: action.payload || "suggestion",
      });
      break;

    case types.UPDATE_PLAYING_TRACK:
      //this action update also LAST SEEN and LAST PLAYED, this means that every time
      //we receive a new playing track the time out for ONLINE and PLAYING are reset.
      let newLocations = Object.assign([], state.locations);

      newLocations.forEach((L) => {
        L.areas.forEach((A) => {
          if (A.device.serial == action.payload.deviceId) {
            //device found
            A.device.last_played = new Date().getTime();
            A.device.last_seen = new Date().getTime();
            A.device.previous_track = Object.assign({}, A.device.current_track);
            A.device.current_track = action.payload.tracksInfo;
          }
        });
      });

      return Object.assign({}, state, { locations: newLocations });

      break;

    case types.FEEDBACK_SUCCESS_MESSAGE_IS_OPEN:
      return Object.assign({}, state, {
        feedbackSuccessMessageIsOpen: action.payload,
      });

    case types.UPDATE_PLAYER_ONLINE_STATUS:
      //this action update LAST SEEN
      let localLocations = Object.assign([], state.locations);

      localLocations.forEach((L) => {
        L.areas.forEach((A) => {
          if (A.device.serial == action.payload.deviceId) {
            A.device.last_seen = new Date().getTime();
          }
        });
      });

      return Object.assign({}, state, { locations: localLocations });

      break;
  }

  return state;
};

export default locationsReducer;
