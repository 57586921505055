import axios from "axios";
import createAuthRefreshInterceptor from "axios-auth-refresh";
// style components
import { DEFAULT_TIMEOUT_INTECEPTORS, LOCAL_STORAGE_USER_TOKEN, LOCAL_STORAGE_USER_REFRESH_TOKEN } from "../config";
import { logout } from "../api/auth-api";
import { storeToken, getRefreshToken } from "../utils/localStorage";
import { getEnvironment } from "@config";

const requestHandler = axios.create({
  baseURL: "",
  timeout: DEFAULT_TIMEOUT_INTECEPTORS,
  responseType: "json",
});

const refreshAuthLogic = (failedRequest) => {
  const refreshToken = getRefreshToken(LOCAL_STORAGE_USER_REFRESH_TOKEN);
  return axios
    .post(`${getEnvironment().HOST_ROCK_API}/auth/refresh-token`, { refreshToken })
    .then((tokenRefreshResponse) => {
      storeToken(tokenRefreshResponse.data.accessToken);
      failedRequest.response.config.headers["Authorization"] = "Bearer " + tokenRefreshResponse.data.accesToken;
      return Promise.resolve();
    })
    .catch(() => {
      logout();
    });
};

// /*
//  Instantiate the interceptor
//  Additional options - AxiosAuthRefreshOptions - to change the default behaviour, can be passed
//  to the `createAuthRefreshInterceptor` function -> docs in https://www.npmjs.com/package/axios-auth-refresh
// */
createAuthRefreshInterceptor(requestHandler, refreshAuthLogic);

requestHandler.interceptors.request.use(
  function (config) {
    const token = localStorage.getItem(LOCAL_STORAGE_USER_TOKEN);

    config.headers["x-ambie-token"] = token;
    config.headers["Authorization"] = "Bearer " + token;
    config.headers["Content-Type"] = "application/json";
    return config;
  },

  async function (error) {
    return Promise.reject(error);
  }
);

export const appendArgsToUrl = (url, queryParams) => {
  const queryString = [];
  Object.keys(queryParams).forEach(function (key) {
    if (queryParams[key] !== null) {
      queryString.push(`${key}=${encodeURIComponent(queryParams[key])}`);
    }
  });
  if (!queryString.length) {
    return url;
  }
  return `${url}?${queryString.join("&")}`;
};

function post(url, data, config) {
  console.log("posting to:", url);
  return requestHandler.post(url, data, config);
}
function get(url, parameter, config) {
  const finalUrl = appendArgsToUrl(url, parameter);
  return requestHandler.get(finalUrl, config);
}

function put(url, data, config) {
  return requestHandler.put(url, data, config);
}

function remove(url, config) {
  return requestHandler.delete(url, config);
}

function patch(url, data, config) {
  return requestHandler.patch(url, data, config);
}

export default Object.freeze({
  get,
  post,
  put,
  remove,
  patch,
});
