import store from "@store";
import { changeLocations, updateDeviceStatus } from "@actions/locations-actions";
import { showSpinner, hideSpinner, showNotification } from "@actions/layout-actions";
import { curationSent, curationSavingError } from "@actions/curation-actions";
import { MINIPLAYER_UPDATE_MESSAGE, GENERAL_REQUEST_ERROR_MESSAGE, getEnvironment } from "@config";
import * as authApi from "./auth-api";
import { changeIsAdminApprovalNeeded } from "@actions/insights-actions";
import axiosInterceptors from "../utils/interceptors";
import { storeFrozenClient } from "../utils/localStorage";
import { freezeClient, showMigrationWarningBar } from "@actions/auth-actions";

/**
 *
 * @param {String} userToken
 * * */
export function getLocations(showTheSpinner) {
  if (typeof showTheSpinner === "undefined") {
    showTheSpinner = true;
  }

  if (showTheSpinner) store.dispatch(showSpinner());
  return axiosInterceptors
    .get(`${getEnvironment().HOST_ROCK_API}/locations/client-locations-areas-devices`, {})
    .then((response) => {
      if (showTheSpinner) {
        store.dispatch(hideSpinner());
      }

      let sortedLocationsObject =
        (response.data && response.data.length && response.data.sort((a, b) => a.name >= b.name)) || [];
      store.dispatch(changeLocations(sortedLocationsObject));
      store.dispatch(
        changeIsAdminApprovalNeeded(
          response.data && response.data.length && response.data[0].feedback_approval_required
        )
      );

      if (response.data.length) {
        const nonFrozenLocations = response.data.filter((location) => !location.is_frozen);
        const migratedClient = new Set([...response.data.filter((location) => location.is_migrated)]);
        if (!migratedClient.size) {
          store.dispatch(showMigrationWarningBar(true));
        } else {
          store.dispatch(showMigrationWarningBar(false));
        }

        if (!nonFrozenLocations.length) {
          storeFrozenClient({
            status: true,
            allLocations: true,
          });
          store.dispatch(freezeClient({ status: true, allLocations: true }));
        } else if (nonFrozenLocations.length !== response.data.length) {
          storeFrozenClient({
            status: true,
            allLocations: false,
          });
          store.dispatch(freezeClient({ status: true, allLocations: false }));
        }
      }
    })
    .catch(() => {
      store.dispatch(hideSpinner());
      store.dispatch(showNotification(GENERAL_REQUEST_ERROR_MESSAGE, true));
    });
}

/**
 *
 * @param {String} userToken
 * @param areaId
 * @param locationId
 * @param scheduleId
 */

/**
 * Update the devices Play state and volume state (i.e. player status)
 *
 * @param {String} userToken
 * @param {String} areaId
 * @param {Object} status
 */
export const updatePlayerStatus = (deviceId, playState, volume) => {
  store.dispatch(showNotification(MINIPLAYER_UPDATE_MESSAGE));

  return axiosInterceptors
    .post(
      `${getEnvironment().HOST_ROCK_API}/devices/update-status/${deviceId}`,
      {
        status: playState,
        volume: volume,
      },
      {}
    )
    .then((response) => {
      store.dispatch(hideSpinner());
      //store.dispatch(updateLocationPlayPauseButton(playState, deviceId));
      store.dispatch(updateDeviceStatus(playState, volume, deviceId));
    })
    .catch((response) => {
      store.dispatch(hideSpinner());
      console.error("Error: ", response);
    });
};

/**
 * API Endpoint - move to the next track in the playlist
 *
 * @param userToken
 * @param deviceId
 */
export const playerSkip = (deviceId) => {
  store.dispatch(showNotification(MINIPLAYER_UPDATE_MESSAGE));
  return axiosInterceptors
    .post(
      `${getEnvironment().HOST_ROCK_API}/devices/skip-track/${deviceId}`,
      {},
      {
        validateStatus: (status) => _handleUnauthorized(status),
      }
    )
    .then((response) => {})
    .catch((response) => {
      store.dispatch(showNotification(GENERAL_REQUEST_ERROR_MESSAGE, true));
      console.error("Error: ", response);
    });
};

export const clientLocationsRequest = async (data) => {
  store.dispatch(showSpinner());
  try {
    await axiosInterceptors.post(`${getEnvironment().HOST_ROCK_API}/locations/client-location-request`, data);
    store.dispatch(curationSent());
  } catch (e) {
    store.dispatch(hideSpinner());
    store.dispatch(curationSavingError());
  }
};

/**
 *
 * @param {error} error
 * @private
 */
function _handleUnauthorized(error) {
  if (error === "Unauthorized" || error === 401) {
    authApi.logout();
    return false;
  }
  return true;
}
