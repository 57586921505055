import * as React from "react";
import { Link } from "react-router-dom";

import { connect } from "react-redux";
import autoBind from "react-autobind";

import store from "./../../store";
import { togglePasswordVisible, setShakeAnimationOff } from "./../../actions/login-actions";
import * as authApi from "./../../api/auth-api";

import { changeContainerHeight, changeForgot } from "./../../actions/layout-actions";
import LoginSplitContainer from "./login-split-container";
import LoadingTypewriter from "@partials/loading-typewriter";

class Login extends React.Component {
  constructor(props) {
    super(props);
    autoBind(this);
    this.state = {
      username: "",
      password: "",
    };
  }

  componentDidMount() {
    mixpanel.track_pageview();
    document.getElementById("login-box").addEventListener("animationend", this._handleAnimationEnd);
  }

  componentWillUnmount() {
    document.getElementById("login-box").removeEventListener("animationend", this._handleAnimationEnd);
  }

  _handlePasswordToggle() {
    store.dispatch(togglePasswordVisible());
  }

  _handleLogin() {
    const username = this.state.username;
    const password = this.state.password;
    const credentials = { username, password };
    authApi.login(credentials);
  }

  _handleAnimationEnd() {
    store.dispatch(setShakeAnimationOff());
  }

  _handleKeyPress(e) {
    if (e.charCode === 13) {
      this._handleLogin();
    }
  }

  handleUsernameInputChange = (e) => {
    const username = e.target.value;
    this.setState({
      ...this.state,
      username,
    });
  };

  handlePasswordInputChange = (e) => {
    const password = e.target.value;
    this.setState({
      ...this.state,
      password,
    });
  };

  render() {
    let containerStyle = { height: this.props.containerHeight };
    let paswordFieldType = this.props.passwordIsVisible ? "text" : "password";
    let passwordToggleMessage = this.props.passwordIsVisible ? "Hide password" : "Show password";
    let loginFormFieldValidClass = this.props.isLoginValid ? "" : "login-input-invalid";
    let loginBoxClass = " col-lg-12";
    loginBoxClass += this.props.showShakeAnimation ? " shake-input-box" : "";
    return (
      <LoginSplitContainer showFooter>
        <div id="login-box" className={loginBoxClass}>
          <LoadingTypewriter show={this.props.showSpinner} />
          <div onKeyPress={this._handleKeyPress} className="login-inputs-wrapper">
            <div className="login-label">Username or Email</div>
            <input
              ref="userField"
              className={loginFormFieldValidClass}
              value={this.state.username}
              onChange={this.handleUsernameInputChange}
              id="username"
              autoCorrect="off"
              autoCapitalize="off"
              autoComplete="off"
              type="text"
            />
            <div className="login-label">Password</div>
            <input
              ref="passwordField"
              className={loginFormFieldValidClass}
              value={this.state.password}
              onChange={this.handlePasswordInputChange}
              id="password"
              autoCorrect="off"
              autoCapitalize="off"
              autoComplete="off"
              type={paswordFieldType}
            />
            <label className="password-toggle">
              <input
                type="checkbox"
                id="show-password"
                className="checkbox-switch"
                onChange={this._handlePasswordToggle}
              />
              <label htmlFor="show-password" />
              {passwordToggleMessage}
            </label>
            <div className="forgot-link">
              <Link to="/forgot-password" href="/forgot-password">
                Forgot password?
              </Link>
            </div>
            <button onClick={this._handleLogin} className="btn btn-primary btn-block btn-lg">
              Login
            </button>
          </div>
        </div>
      </LoginSplitContainer>
    );
  }
}

const storeToProps = (store) => {
  return {
    passwordIsVisible: store.loginState.passwordIsVisible,
    isLoginValid: store.loginState.isLoginValid,
    showShakeAnimation: store.loginState.showShakeAnimation,
    containerHeight: store.layoutState.containerHeight,
    showSpinner: store.layoutState.showSpinner,
  };
};

export default connect(storeToProps)(Login);
