import React from "react";

const LocationSvg = (props) => (
  <svg
    version="1.1"
    id="Capa_1"
    x="0px"
    y="0px"
    viewBox="0 0 512 512"
    fill={props.fill || "none"}
    stroke={props.stroke || "none"}
    strokeWidth={props.strokeWidth || 1}
  >
    <g>
      <g>
        <path
          d="M256,80c-68.925,0-125,56.075-125,125s56.075,125,125,125c11.641,0,23.175-1.603,34.282-4.765
          c10.624-3.023,16.784-14.087,13.761-24.711c-3.025-10.623-14.087-16.785-24.712-13.76C271.787,288.911,263.937,290,256,290
          c-46.869,0-85-38.131-85-85s38.131-85,85-85c46.869,0,85,38.131,85,85c0,8.339-1.2,16.57-3.567,24.463
          c-3.173,10.58,2.832,21.729,13.412,24.902c10.581,3.171,21.728-2.831,24.902-13.412C379.232,229.33,381,217.234,381,205
          C381,136.075,324.925,80,256,80z"
        />
      </g>
    </g>
    <g>
      <g>
        <path
          d="M400.877,59.964C362.167,21.296,310.715,0,256,0c-54.716,0-106.167,21.296-144.877,59.965
          c-38.706,38.666-60.057,90.088-60.12,144.838c0.039,39.855,11.109,77.247,33.841,114.315
          c19.685,32.097,45.258,60.103,72.332,89.753c26.547,29.071,53.996,59.133,75.918,93.815C236.759,508.484,243.141,512,250,512h12
          c6.859,0,13.241-3.516,16.906-9.315c21.922-34.682,49.371-64.744,75.918-93.815c27.074-29.649,52.647-57.655,72.332-89.753
          c22.733-37.068,33.802-74.461,33.841-114.358C460.935,150.052,439.583,98.63,400.877,59.964z M325.286,381.899
          c-23.604,25.849-47.891,52.447-69.286,83.037c-21.394-30.59-45.682-57.188-69.286-83.037
          c-51.319-56.201-95.641-104.739-95.711-177.093C91.107,113.932,165.125,40,256,40s164.893,73.932,164.997,164.763
          C420.927,277.16,376.605,325.697,325.286,381.899z"
        />
      </g>
    </g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
  </svg>
);

export default LocationSvg;
