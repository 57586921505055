import * as types from "./../actions/types";

const initalState = {
  showPreviewScreen: false,
  showCuratorContactForm: false,
  currentChannelIndex: -1,
  playlists: [],
  favourites: [],
  channels: [],
  nowPlaying: [],
};

const channelsReducer = (state = initalState, action) => {
  switch (action.type) {
    case types.CHANGE_CHANNELS:
      return Object.assign({}, state, {
        channels: action.payload,
      });
      break;

    case types.GET_CHANNEL_PLAYLISTS:
      return Object.assign({}, state, {
        playlists: action.payload,
      });
      break;

    case types.CHECK_IS_A_FAVOURITE:
      let deepCopyPlaylists = state.playlists.slice();
      for (var i = 0; i < state.favourites.length; i++) {
        for (var j = 0; j < deepCopyPlaylists.length; j++) {
          if (deepCopyPlaylists[j].id === state.favourites[i].id) {
            deepCopyPlaylists[j].inFavourites = true;
          }
        }
      }
      return Object.assign({}, state, {
        playlists: deepCopyPlaylists,
      });
      break;

    case types.ADD_TO_FAVOURITES:
      let newPlaylist = state.favourites.concat([action.payload]);
      return Object.assign({}, state, {
        favourites: newPlaylist,
      });
      break;

    case types.REMOVE_FROM_FAVOURITES:
      let removePlaylist = state.favourites.filter((item) => {
        return item !== action.payload;
      });
      return Object.assign({}, state, {
        favourites: removePlaylist,
      });
      break;

    case types.CHANGE_SHOW_PREVIEW_SCREEN:
      return Object.assign({}, state, {
        showPreviewScreen: action.payload,
      });
      break;

    case types.CHANGE_CURRENT_CHANNEL_INDEX:
      return Object.assign({}, state, {
        currentChannelIndex: action.payload,
      });
      break;

    case types.CHANGE_SHOW_CURATOR_CONTACT_FORM:
      return Object.assign({}, state, {
        showCuratorContactForm: action.payload,
      });
      break;

    case types.GET_FAVOURITE_PLAYLISTS:
      return Object.assign({}, state, {
        favourites: action.payload,
      });
      break;

    case types.PAUSE_ALL_PLAYLISTS:
      let favouritesPaused = state.favourites.map((pl) => {
        pl.playing = false;
        return pl;
      });
      let playlistsPaused = state.playlists.map((pl) => {
        pl.playing = false;
        return pl;
      });
      return Object.assign({}, state, {
        favourites: favouritesPaused,
        playlists: playlistsPaused,
      });
      break;

    case types.PLAY_ONE_PLAYLIST:
      let favouritesPlay = state.favourites.map((pl) => {
        if (pl.id === action.payload.id) pl.playing = true;
        return pl;
      });
      let playlistsPlay = state.playlists.map((pl) => {
        if (pl.id === action.payload.id) pl.playing = true;
        return pl;
      });
      return Object.assign({}, state, {
        favourites: favouritesPlay,
        playlists: playlistsPlay,
      });
      break;

    case types.UPDATE_PROGRESS_BAR:
      let favouritesProgress = state.favourites.map((pl) => {
        if (pl.id === action.payload) pl.previewTimeElapsed++;
        return pl;
      });
      let playlistsProgress = state.playlists.map((pl) => {
        if (pl.id === action.payload) pl.previewTimeElapsed++;
        return pl;
      });
      return Object.assign({}, state, {
        favourites: favouritesProgress,
        playlists: playlistsProgress,
      });
      break;

    case types.RESET_PROGRESS_BAR:
      let favouritesReset = state.favourites.map((pl) => {
        if (pl.id === action.payload) pl.previewTimeElapsed = 0;
        return pl;
      });
      let playlistsReset = state.playlists.map((pl) => {
        if (pl.id === action.payload) pl.previewTimeElapsed = 0;
        return pl;
      });
      return Object.assign({}, state, {
        favourites: favouritesReset,
        playlists: playlistsReset,
      });
      break;

    case types.UPDATE_PREVIEW_DETAILS:
      return Object.assign({}, state, {
        nowPlaying: action.payload,
      });
      break;
  }

  return state;
};

export default channelsReducer;
