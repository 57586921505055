import * as types from "./../actions/types";
import { LOCAL_STORAGE_SKIPS } from "./../config";

const storedSkips = JSON.parse(localStorage.getItem(LOCAL_STORAGE_SKIPS)) || [];

const initalState = {
  isVolumeControlOpen: false,
  isDeviceActivated: true,
  skips: storedSkips,
  isChannelSwitcherOpen: false,
  isTermsOpen: false,
  changeChannelStepValue: 1,
  isInitialUpdate: true,
  isStickyMessage: false,
  trackProgress: 0,
  skipRefresh: 0,
  playerStatus: {
    volume: 0,
    status: false, //isPlaying
    autoplay: false,
    isScheduleOverwritten: false,
    last_seen: new Date().getTime(),
    track: {
      playlist: "",
      artist: "",
      duration: 0,
      title: "",
      start: 0,
      genre: "",
    },
    previous_track: {
      playlist: "",
      artist: "",
      duration: 0,
      title: "",
      start: 0,
      genre: "",
    },
  },
  currentChannel: {
    playlist: {
      name: "",
    },
  },
  availableChannels: [],
  availableDurations: [0.25, 0.5, 1, 2, 3, 4, 5, 6, 7, 8],
  selectedNewChannel: 0, //used for changing the channel
  selectedNewDuration: 0.25, //used for changing the channel
  schedule: [],
  currentDayOffset: 0,
  infoMessage: "",
  showInfoMessage: false,
  availableBgColours: {
    "R&B": {
      start: "#e255df",
      stop: "#79d0d1",
    },
    "Hip Hop": {
      start: "#39c8a6",
      stop: "#f16c80",
    },
    Jazz: {
      start: "#a17dec",
      stop: "#39c8a6",
    },
    Reggae: {
      start: "#f9c278",
      stop: "#addd5e",
    },
    Pop: {
      start: "#f991e1",
      stop: "#f16c80",
    },
    Rock: {
      start: "#e255df",
      stop: "#39c8a6",
    },
    Electronic: {
      start: "#79d0d1",
      stop: "#a17dec",
    },
    World: {
      start: "#addd5e",
      stop: "#39c8a6",
    },
    Other: {
      start: "#f16c80",
      stop: "#79d0d1",
    },
    Classical: {
      start: "#addd5e",
      stop: "#5e95d3",
    },
  },
};

const playerReducer = (state = initalState, action) => {
  switch (action.type) {
    case types.OPEN_VOLUME_CONTROL:
      return Object.assign({}, state, {
        isVolumeControlOpen: true,
      });
    case types.CLOSE_VOLUME_CONTROL:
      return Object.assign({}, state, {
        isVolumeControlOpen: false,
      });
    case types.CHANGE_SKIP_LIST:
      return Object.assign({}, state, {
        skips: action.payload,
      });
    case types.CHANGE_CHANNEL_TOGGLE:
      //console.log('state.changeChannelIsOpen: ', state.changeChannelIsOpen);
      return Object.assign({}, state, {
        changeChannelIsOpen: !state.changeChannelIsOpen,
      });
    case types.CHANGE_TERMS_OPEN:
      return Object.assign({}, state, {
        isTermsOpen: !state.isTermsOpen,
      });
    case types.CHANGE_CHANNEL_STEP:
      return Object.assign({}, state, {
        changeChannelStepValue: action.payload || 1,
      });
    case types.CHANGE_PLAYER_STATUS:
      return Object.assign({}, state, {
        playerStatus: action.payload,
      });
    case types.CHANGE_AVAILABLE_CHANNELS:
      return Object.assign({}, state, {
        availableChannels: action.payload,
      });
    case types.CHANGE_SELECTED_NEW_CHANNEL:
      return Object.assign({}, state, {
        selectedNewChannel: action.payload,
      });
    case types.CHANGE_SELECTED_NEW_DURATION:
      return Object.assign({}, state, {
        selectedNewDuration: action.payload,
      });
    case types.CHANGE_SCHEDULE:
      return Object.assign({}, state, {
        schedule: action.payload,
      });
    case types.CHANGE_CURRENT_CHANNEL:
      return Object.assign({}, state, {
        currentChannel: action.payload,
      });
    case types.CHANGE_INFO_MESSAGE:
      return Object.assign({}, state, {
        infoMessage: action.payload,
      });
    case types.SHOW_INFO_MESSAGE:
      // debugger;
      return Object.assign({}, state, {
        showInfoMessage: true,
        infoMessage: action.payload.message,
        isStickyMessage: action.payload.isSticky || false,
      });
    case types.HIDE_INFO_MESSAGE:
      return Object.assign({}, state, {
        showInfoMessage: false,
      });
    case types.CHANGE_IS_INITIAL_UPDATE:
      return Object.assign({}, state, {
        isInitialUpdate: action.payload,
      });
    case types.CHANGE_TRACK_PROGRESS:
      return Object.assign({}, state, {
        trackProgress: action.payload,
      });
    case types.CHANGE_SKIP_REFRESH:
      return Object.assign({}, state, {
        skipRefresh: action.payload,
      });
    case types.CHANGE_CURRENT_DAY_OFFSET:
      return Object.assign({}, state, {
        currentDayOffset: action.payload,
      });
  }

  return state;
};

export default playerReducer;
