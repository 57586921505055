import * as types from "./types";

export const addPlaylist = (playlist) => {
  return {
    type: types.PLAYLISTS_ADD_PLAYLIST,
    payload: playlist,
  };
};

export const addPlaylists = (payload) => ({
  type: types.PLAYLISTS_ADD_PLAYLISTS,
  payload,
});

export const addSongToPlaylist = (song, playlist) => {
  return {
    type: types.PLAYLISTS_ADD_SONG_TO_PLAYLIST,
    payload: {
      song: song,
      playlist: playlist,
    },
  };
};

export const loadSongUrl = (playlistID, songID, previewUrl) => {
  return {
    type: types.PLAYLISTS_LOAD_URL,
    payload: {
      playlistID: playlistID,
      songID: songID,
      url: previewUrl,
    },
  };
};

export const playSongPreview = (playlistID, songID) => {
  return {
    type: types.PLAYLISTS_PLAY_PREVIEW,
    payload: {
      playlistID: playlistID,
      songID: songID,
    },
  };
};

export const pauseSongPreview = (playlistID, songID) => {
  return {
    type: types.PLAYLISTS_PAUSE_PREVIEW,
    payload: {
      playlistID: playlistID,
      songID: songID,
    },
  };
};

export const setAsPreviewed = (playlistId) => {
  return {
    type: types.PLAYLISTS_SET_PREVIEWED,
    payload: playlistId,
  };
};

export const resetPreviewed = () => {
  return {
    type: types.PLAYLISTS_RESET_PREVIEWED,
  };
};

export const elapseTrackProgress = (songID) => {
  return {
    type: types.PLAYLISTS_ELAPSE_TRACK_PROGRESS,
    payload: songID,
  };
};

export const resetElapseTrackProgress = (songID) => {
  return {
    type: types.PLAYLISTS_RESET_ELAPSE_TRACK_PROGRESS,
    payload: songID,
  };
};

export const updatePreviewNow = (previewUrl) => {
  return {
    type: types.PLAYLIST_UPDATE_PREVIEW_NOW,
    payload: previewUrl,
  };
};

export const updatePreviewNext = (previewUrl) => {
  return {
    type: types.PLAYLIST_UPDATE_PREVIEW_NEXT,
    payload: previewUrl,
  };
};

export const clearPreviewQueue = () => {
  return {
    type: types.PLAYLIST_CLEAR_PREVIEW_QUEUE,
    payload: "",
  };
};

export const cleanQueue = () => {
  return {
    type: types.PLAYLIST_CLEAN_QUEUE,
    payload: "",
  };
};

export const setSongIsPlaying = (songID) => {
  return {
    type: types.PLAYLIST_SET_SONG_AS_NOW_PLAYING,
    payload: songID,
  };
};

export const setSongNotPlaying = (songID) => {
  return {
    type: types.PLAYLIST_SET_SONG_AS_NOT_PLAYING,
    payload: songID,
  };
};

export const setPlaylistIsPlaying = (playlistID) => {
  return {
    type: types.PLAYLIST_SET_PLAYLIST_AS_NOW_PLAYING,
    payload: playlistID,
  };
};

export const setPlaylistNotPlaying = (playlistID) => {
  return {
    type: types.PLAYLIST_SET_PLAYLIST_AS_NOT_PLAYING,
    payload: playlistID,
  };
};

export const updateCurrentInfo = (plIndex, plID, songIndex, songID) => {
  return {
    type: types.PLAYLIST_UPDATE_CURRENT,
    payload: {
      playlistIndex: plIndex,
      playlistID: plID,
      songIndex: songIndex,
      songID: songID,
    },
  };
};

export const listenInPlaylist = (payload) => ({
  type: types.LISTEN_IN_PLAYLIST,
  payload,
});
