import React, { useEffect } from "react";

export function FreshchatIntegration({ name }) {
  useEffect(() => {
    const freshchatScript = document.createElement("script");
    freshchatScript.async = true;
    freshchatScript.src = "https://ambie.freshchat.com/js/widget.js";
    document.head.appendChild(freshchatScript);

    freshchatScript.onload = () => {
      // Freshchat SDK has loaded

      window.fcWidget.init({
        token: "2864b4b2-3f5c-4573-ae36-7286e32a788b",
        host: "https://ambie.freshchat.com",
      });
      // To set unique user id in your system when it is available
      // window.fcWidget.setExternalId("john.doe1987");

      // To set user name
      window.fcWidget.user.setFirstName(name || "Unknown");

      // To set user email
      //   window.fcWidget.user.setEmail("john.doe@gmail.com");
    };

    return () => {
      // Clean up the script tag
      document.head.removeChild(freshchatScript);
    };
  }, []);

  return <div id="freshchat-container" />;
}
