import store from "./../store";
import { changeChannels, changeFavourites } from "./../actions/channels-actions";
import { showSpinner, hideSpinner, showNotification } from "./../actions/layout-actions";
import { GENERAL_REQUEST_ERROR_MESSAGE, getEnvironment } from "./../config";
import * as authApi from "./auth-api";
import axiosInterceptors from "../utils/interceptors";

export function getFavourites() {
  return axiosInterceptors
    .get(`${getEnvironment().HOST_ROCK_API}/playlists/dashboard/client/with-tracks`, {
      validateStatus: (status) => _handleUnauthorized(status),
    })
    .then((response) => {
      let favouritesRemapped = _remapData(response.data.playlists, true);
      store.dispatch(changeFavourites(favouritesRemapped));
    })
    .catch((response) => {
      store.dispatch(showNotification(GENERAL_REQUEST_ERROR_MESSAGE, true));
    });
}

export const getChannels = async () => {
  try {
    store.dispatch(showSpinner());
    const response = await axiosInterceptors.get(`${getEnvironment().HOST_ROCK_API}/playlists/dashboard/client`, {
      validateStatus: (status) => _handleUnauthorized(status),
    });

    store.dispatch(hideSpinner());
    store.dispatch(changeChannels(response.data.results));
  } catch (e) {
    store.dispatch(hideSpinner());
    store.dispatch(showNotification(GENERAL_REQUEST_ERROR_MESSAGE, true));
    console.error("Error: ", e);
  }
};

function _handleUnauthorized(error) {
  if (error === "Unauthorized" || error === "Invalid token" || error === 401) {
    authApi.logout();
    return false;
  }
  return true;
}

/**
 * Remap the data from the API to a format the
 * user interface can use
 *
 * @param {Array.<Object>} data
 * @param {Boolean} isFavourite
 */
function _remapData(data, isFavourite) {
  let remappedData = data.map((list) => {
    let remappedGenresArray = [];
    let remappedArtistArray = [];

    if (list.featured_artists !== null) {
      remappedArtistArray = list.featured_artists.split(",");
    }

    if (list.featured_genres !== null) {
      remappedGenresArray = list.featured_genres.split(",");
    }

    list.featured_artists = remappedArtistArray;

    list.featured_genres = remappedGenresArray;

    list.moods = [];

    list.inFavourites = isFavourite;

    list.playing = false;

    list.previewTimeElapsed = 0;

    if (list.hasOwnProperty("tracks")) {
      list.totalPreviewTime = list.tracks.length * 10;
    } else {
      list.tracks = [];
      list.totalPreviewTime = 0;
    }

    return list;
  });

  return remappedData;
}
