import React from "react";
import { Route, Switch, Redirect } from "react-router-dom";

import store from "./store";
import MainLayout from "./components/layout/main-layout";
import Login from "./components/auth/login";
import SetPassword from "./components/auth/set-password";
import ForgotPassword from "./components/auth/forgot-password";
import LoginAsAmbieUsers from "./components/auth/login-as-ambie-users";

const _isLoggedIn = () => store.getState().authState.userInfo.userToken;

export default (
  <Switch>
    <Route
      path="/forgot-password"
      render={(props) => (_isLoggedIn() ? <Redirect to="/locations" /> : <ForgotPassword {...props} />)}
    />
    <Route
      path="/login-as-ambie-users"
      render={(props) => (_isLoggedIn() ? <Redirect to="/locations" /> : <LoginAsAmbieUsers {...props} />)}
    />
    <Route
      path="/reset-password"
      render={(props) => (_isLoggedIn() ? <Redirect to="/locations" /> : <SetPassword {...props} />)}
    />
    <Route path="/login" render={(props) => (_isLoggedIn() ? <Redirect to="/locations" /> : <Login {...props} />)} />
    <Route path="/" render={(props) => (_isLoggedIn() ? <MainLayout /> : <Redirect to="/login" />)} />
  </Switch>
);
