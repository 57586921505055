import * as types from "./types";

export function changeLocationSearchQuery(newQuery) {
  return {
    type: types.CHANGE_LOCATIONS_SEARCH_QUERY,
    payload: newQuery,
  };
}

export function changeLocations(newLocations) {
  return {
    type: types.CHANGE_LOCATIONS,
    payload: newLocations,
  };
}

export function changeShowChangeScheduleDialog(newValue) {
  return {
    type: types.CHANGE_SHOW_CHANGE_SCHEDULE_DIALOG,
    payload: newValue,
  };
}

export function changeAreaScheduleInfo(payload) {
  return {
    type: types.CHANGE_AREA_SCHEDULE_INFO,
    payload: payload,
  };
}

export function getAreaInfo(payload) {
  return {
    type: types.GET_AREA_INFO,
    payload: payload,
  };
}

export function changeShowControlMusic() {
  return {
    type: null,
  };
}

export function toggleChangeChannel(isOpen) {
  return {
    type: types.TOGGLE_CHANGE_CHANNEL,
    payload: isOpen,
    //  meta : {
    //  MP_Action : "Miniplayer",
    //  MP_Payload : {
    //   Override : "Open"
    //  }
    // }
  };
}

export function openOverrideConfirmationPopup(isConfirmationBox) {
  return {
    type: types.OPEN_OVERRIDE_CONFIRMATION_POPUP,
    payload: isConfirmationBox,
  };
}

export function closeOverrideConfirmationPopup() {
  return {
    type: types.CLOSE_OVERRIDE_CONFIRMATION_POPUP,
  };
}

export function updateCurrentOpenPlayer(playerId) {
  return {
    type: types.UPDATE_CURRENT_OPEN_PLAYER,
    payload: playerId,
  };
}

export function closeChangeChannel() {
  return {
    type: types.CLOSE_CHANGE_CHANNEL,
  };
}

export function toggleReport() {
  return {
    type: types.TOGGLE_REPORT,
  };
}

export function closeReport() {
  return {
    type: types.CLOSE_REPORT,
  };
}

export function changeReportStep(newValue) {
  return {
    type: types.CHANGE_REPORT_STEP,
    payload: newValue,
  };
}

export function changeReportType(newType) {
  return {
    type: types.CHANGE_REPORT_TYPE,
    payload: newType,
  };
}

export function changeReportSubType(newSubType) {
  return {
    type: types.CHANGE_REPORT_SUB_TYPE,
    payload: newSubType,
  };
}

export function changeDeviceStatus(newStatus) {
  return {
    type: types.CHANGE_DEVICE_STATUS,
    payload: newStatus,
  };
}

export function updateReportTracks(payload) {
  return {
    type: types.CHANGE_REPORT_TRACKS,
    payload: payload,
  };
}

export function closeAllDevicePanels() {
  return {
    type: types.CLOSE_DEVICE_PANELS,
    payload: true,
  };
}

export function setNowPlaying(deviceId, nowPlaying) {
  return {
    type: types.SET_NOW_PLAYING,
    payload: [deviceId, nowPlaying],
  };
}

export function updatePlayingTrack(tracksInfo, deviceId) {
  return {
    type: types.UPDATE_PLAYING_TRACK,
    payload: {
      tracksInfo: tracksInfo,
      deviceId: deviceId,
    },
  };
}

export function updatePlayerOnlineStatus(deviceId) {
  return {
    type: types.UPDATE_PLAYER_ONLINE_STATUS,
    payload: {
      deviceId: deviceId,
    },
  };
}

export function feedbackSuccessMessageIsOpen(isOpen) {
  return {
    type: types.FEEDBACK_SUCCESS_MESSAGE_IS_OPEN,
    payload: isOpen,
  };
}

export function updateDeviceStatus(playState, volume, deviceId) {
  return {
    type: types.UPDATE_DEVICE_STATUS,
    payload: {
      status: playState ? "play" : "pause",
      volume: volume === null ? 0 : volume,
      deviceId: deviceId,
    },
  };
}

export function updateLocationPlayPauseButton(playState, deviceId) {
  return {
    type: types.UPDATE_LOCATION_PLAY_PAUSE_BUTTON,
    payload: {
      playState: playState,
      deviceId: deviceId,
    },
  };
}
