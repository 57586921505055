import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";

import * as gaAnalytics from "@common/gaAnalyticsHelper";
import { loginIntoClientAsAmbie } from "@api/auth-api";
import LoginSplitContainer from "./login-split-container";
import LoadingTypewriter from "@partials/loading-typewriter";

const LoginAsAmbieUsers = (props) => {
  const searchParams = new URLSearchParams(props.location.search);

  const clientId = searchParams.get("clientId");
  const clientName = searchParams.get("clientName");
  const userId = searchParams.get("userId");
  const username = searchParams.get("username");
  const token = searchParams.get("token");
  const parts = token.split("$$$");
  let extractedTimestamp = parts[1];

  useEffect(() => {
    gaAnalytics.trackPage(props.location.pathname);
  }, []);

  let interval = setInterval(() => {
    window.location.reload();
    clearInterval(interval);
  }, 1000 * 60);

  const handleSubmit = async () => {
    await loginIntoClientAsAmbie({ username, userId, clientId });
  };

  return (
    <LoginSplitContainer showFooter>
      <div className="login-container">
        <div id="login-box" className="col-lg-12">
          <LoadingTypewriter show={props.showSpinner} />
          {clientId && userId && extractedTimestamp > Date.now() ? (
            <div className="login-inputs-wrapper">
              <p>
                Click on the submit button to login into <i>{clientName}</i> dashboard as Ambie user!
              </p>

              <div style={{ marginTop: "1em" }}>
                <button style={{ marginRight: "1em" }} className="btn btn-danger btn-rounded">
                  <Link className="text-white" to="/login" href="/login">
                    CANCEL
                  </Link>
                </button>
                <button className="btn btn-primary btn-rounded" onClick={handleSubmit}>
                  SUBMIT
                </button>
              </div>
            </div>
          ) : (
            <div className="login-inputs-wrapper">
              <p>
                Unfortunately, you cannot login into <i>{clientName}</i> dashboard as Ambie user!
              </p>
              <button
                style={{ marginRight: "1em" }}
                className="btn btn-primary"
                onClick={() => (window.location.href = `/login`)}
              >
                BACK
              </button>
            </div>
          )}
        </div>
      </div>
    </LoginSplitContainer>
  );
};

const storeToProps = (store) => {
  return {
    showSpinner: store.layoutState.showSpinner,
  };
};

export default connect(storeToProps)(LoginAsAmbieUsers);
