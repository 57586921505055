import * as types from "./../actions/types";
import * as config from "./../config";

const initalState = {
  due_date: "",
  anything: "",
  contact_email: "",
  description: "",
  ambie_to_schedule: "",
  request_type: "",
  displayConfimationNote: false,
  displayErrorNote: false,
  affected_location: "all",
  affected_locations: [],
  curationRequests: [],
};

const curationReducer = (state = initalState, action) => {
  switch (action.type) {
    case types.CHANGE_SUBMITTED_CURATION_REQUESTS: {
      return {
        ...state,
        curationRequests: action.payload,
      };
    }
    case types.UPDATE_CURATION_FIELD: {
      let newState = Object.assign({}, state);
      newState[action.payload.field] = action.payload.value;
      return Object.assign({}, state, newState);
    }
    case types.CLEAR_FIELDS: {
      return {
        due_date: "",
        anything: "",
        contact_email: "",
        description: "",
        ambie_to_schedule: "",
        request_type: "",
        displayConfimationNote: false,
        displayErrorNote: false,
        affected_location: "all",
        affected_locations: [],
      };
    }
    case types.CURATION_SENT: {
      return Object.assign({}, state, {
        displayConfimationNote: true,
        displayErrorNote: false,
      });
    }
    case types.CURATION_SAVING_ERROR: {
      return Object.assign({}, state, {
        displayErrorNote: true,
        displayConfimationNote: false,
      });
    }
    case types.CLOSE_CURATION_MODALS: {
      return Object.assign({}, state, {
        displayErrorNote: false,
        displayConfimationNote: false,
      });
    }
    case types.SELECT_LOCATIONS: {
      return {
        ...state,
        affected_locations: action.payload,
      };
    }
    case types.DESELECT_ALL_LOCATIONS: {
      return {
        ...state,
        affected_locations: [],
      };
    }
    case types.CURATION_TOGGLE_AFFECTED_LOCATION: {
      const affected_locations = [...state.affected_locations];
      if (affected_locations.includes(parseInt(action.payload, 10))) {
        affected_locations.splice(affected_locations.indexOf(action.payload), 1);
      } else {
        affected_locations.push(action.payload);
      }
      return {
        ...state,
        affected_locations,
      };
    }
  }

  return state;
};

export default curationReducer;
