import { createStore, combineReducers, applyMiddleware } from "redux";
import reducers from "./reducers";

let logger = {};
let store = {};

if (USE_REDUX_LOGGER === "true") {
  let createLogger = require("redux-logger").createLogger;
  //import { createLogger } from 'redux-logger';
  logger = createLogger({
    collapsed: true,
  });
}

//import MQTTClient from './components/AWSIotClient';
let mixpanelMiddleware = (store) => (next) => (action) => {
  //console.log('dispatching', action);

  if (typeof action.meta !== "undefined") {
    //debugger;
    //console.log(action.meta);
    window.mixpanel.track(action.meta.MP_Action, action.meta.MP_Payload);
  }

  let result = next(action);
  //console.log('next state', store.getState());
  return result;
};

if (USE_REDUX_LOGGER === "false") {
  store = createStore(
    reducers
    //window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__(),
    // applyMiddleware(mixpanelMiddleware)
    //applyMiddleware(logger, mixpanelMiddleware)
  );
}

if (USE_REDUX_LOGGER === "true") {
  store = createStore(
    reducers
    //window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__(),
    // applyMiddleware(mixpanelMiddleware)
    // applyMiddleware(logger, mixpanelMiddleware)
  );
}

export default store;
