import * as types from "./types";

export function togglePasswordVisible() {
  return {
    type: types.TOGGLE_PASSWORD_VISIBILITY,
  };
}

export function setLoginFormValid() {
  return {
    type: types.SET_LOGIN_VALID,
  };
}

export function setLoginFormInvalid() {
  return {
    type: types.SET_LOGIN_INVALID,
  };
}

export function setShakeAnimationOff() {
  return {
    type: types.SET_SHAKE_ANIMATION_OFF,
  };
}
