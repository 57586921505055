import * as types from "../actions/types";
import { cloneDeep } from "lodash";

const initialState = {
  playlists: [],
  playlistPreviewed: {},
  previewURL_now: "",
  previewURL_next: "",
  current: {
    playlistIndex: null,
    playlistID: null,
    songIndex: null,
    songID: null,
  },
  previous: {
    playlistIndex: null,
    playlistID: null,
    songIndex: null,
    songID: null,
  },
  listenIn: {
    show: false,
    playTrack: false,
    serialNumber: "",
  },
};

const playlistsReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.PLAYLIST_UPDATE_CURRENT:
      // debugger;
      return Object.assign({}, state, {
        previous: state.current,
        current: action.payload,
      });
      break;

    case types.PLAYLIST_UPDATE_PREVIEW_NOW:
      return Object.assign({}, state, {
        previewURL_now: action.payload,
      });
      break;

    case types.PLAYLIST_UPDATE_PREVIEW_NEXT:
      return Object.assign({}, state, {
        previewURL_next: action.payload,
      });
      break;

    case types.PLAYLIST_CLEAN_QUEUE:
      return Object.assign({}, state, {
        previewURL_now: state.previewURL_next,
        previewURL_next: "",
      });
      break;

    // delete all urls on the queue
    case types.PLAYLIST_CLEAR_PREVIEW_QUEUE:
      return Object.assign({}, state, {
        previewURL_now: "",
        previewURL_next: "",
      });
      break;

    // called from the API when first loading in the playlists from the BE
    case types.PLAYLISTS_ADD_PLAYLIST:
      const newPlaylistsArray = cloneDeep(state.playlists);
      const existingPlaylist = newPlaylistsArray.filter((pl) => pl.id === action.payload.id)[0];
      if (!existingPlaylist) {
        newPlaylistsArray.push(action.payload);
      }

      return Object.assign({}, state, {
        playlists: newPlaylistsArray,
      });
      break;
    // called from the API when first loading in the playlists from the BE
    case types.PLAYLISTS_ADD_PLAYLISTS:
      return Object.assign({}, state, {
        playlists: action.payload,
      });

    // find the songs object and set the previewURL
    case types.PLAYLISTS_LOAD_URL:
      let loadUrlObject = cloneDeep(state.playlists);

      // Assign the track the preview URL
      loadUrlObject
        // drill into the songs playlist
        .filter((playlist) => {
          // get its array of tracks
          return playlist.id === action.payload.playlistID;
        })[0]
        .tracks // find the song in the list
        .filter((track) => {
          // pin point the previewURL field
          return track.id === action.payload.songID;
        })[0].previewUrl = action.payload.url;

      return Object.assign({}, state, {
        playlists: loadUrlObject,
      });
      break;

    // ???????
    case types.PLAYLISTS_PLAY_PREVIEW:
      let newIsPlayingArray = cloneDeep(state.playlists);

      newIsPlayingArray.map((playlist) => {
        playlist.id === action.payload.playlistID
          ? (playlist.isBeingPreviewed = true)
          : (playlist.isBeingPreviewed = false);
      });

      return Object.assign({}, state, {
        playlists: newIsPlayingArray,
      });
      break;

    case types.PLAYLISTS_PAUSE_PREVIEW:
      let pausedPlaylistObject = cloneDeep(state.playlists);

      pausedPlaylistObject
        .filter((playlist) => playlist.id === action.payload.playlistID)
        .filter((playlist) => playlist.tracks === action.payload.songID);

      return Object.assign({}, state, {
        playlists: pausedPlaylistObject,
      });
      break;

    // set the playlistPreviewed
    case types.PLAYLISTS_SET_PREVIEWED:
      return Object.assign({}, state, {
        playlistPreviewed: state.playlists.filter((playlist) => playlist.id === action.payload)[0],
      });
      break;

    case types.PLAYLISTS_RESET_PREVIEWED:
      return Object.assign({}, state, {
        playlistPreviewed: {},
      });
      break;

    // increment the songs elapsed time for the UI progress bar
    case types.PLAYLISTS_ELAPSE_TRACK_PROGRESS:
      let trackElapsedObject = cloneDeep(state.playlistPreviewed);

      // will be null on first selected playlist -
      // i.e no song playing yet to cancel
      if (trackElapsedObject && trackElapsedObject.tracks && action.payload !== null) {
        let elapsedTrack = trackElapsedObject.tracks.filter((track) => track.id === action.payload)[0];

        // if the track can be found. By toggling between playlists we
        // may not be able to get the song object from the action.payload (songID)
        // as the playlistPreviewed object isnt what it used to be
        if (elapsedTrack !== undefined) elapsedTrack.previewElapsed++;
      }

      return Object.assign({}, state, {
        playlistPreviewed: trackElapsedObject,
      });
      break;

    case types.PLAYLISTS_RESET_ELAPSE_TRACK_PROGRESS:
      let resetElapsedObject = cloneDeep(state.playlistPreviewed);

      // will be null on first selected playlist -
      // i.e no song playing yet to cancel
      if (action.payload !== null) {
        let resetElapsedTrack = resetElapsedObject.tracks.filter((track) => track.id === action.payload)[0];

        // if the track can be found. By toggling between playlists we
        // may not be able to get the song object from the action.payload (songID)
        // as the playlistPreviewed object isnt what it used to be
        if (resetElapsedTrack !== undefined) resetElapsedTrack.previewElapsed = -1;
      }

      return Object.assign({}, state, {
        playlistPreviewed: resetElapsedObject,
      });
      break;

    // set 'isPlaying = true' in the SONG object
    // tracks.js only listens to whats in "state.playlistPreviewed"
    case types.PLAYLIST_SET_SONG_AS_NOW_PLAYING:
      let songNowPlaying_array = cloneDeep(state.playlistPreviewed);

      // will be null on first selected playlist -
      // i.e no song playing yet to cancel
      if (action.payload !== null) {
        let songNowPlaying_track = songNowPlaying_array.tracks.filter((track) => track.id === action.payload)[0];

        // if the track can be found. By toggling between playlists we
        // may not be able to get the song object from the action.payload (songID)
        // as the playlistPreviewed object isnt what it used to be
        if (songNowPlaying_track !== undefined) songNowPlaying_track.isPlaying = true;

        console.log(
          `%c ${JSON.stringify(songNowPlaying_track)}`,
          "background-color: grey; color: green; font-size: 12px;"
        );

        // mixpanel.track("Playlist Preview", {
        //   ["Play Track"]: `${songNowPlaying_track.artist} - ${songNowPlaying_track.title}`,
        // });
      }

      return Object.assign({}, state, {
        playlistPreviewed: songNowPlaying_array,
      });
      break;

    // set 'isPlaying = false' in the SONG object
    // tracks.js only listens to whats in "state.playlistPreviewed"
    case types.PLAYLIST_SET_SONG_AS_NOT_PLAYING:
      // Sometimes we are dealing with DJ mixes or just simply playlists with
      // no tracks so we much wrap the function in a null check
      if (action.payload !== null) {
        let songNotPlaying_array = cloneDeep(state.playlistPreviewed);
        // will be null on first selected playlist -
        // i.e no song is playing yet to set as 'not playing'
        if (typeof songNotPlaying_array.tracks != "undefined") {
          let songNotPlaying_track = songNotPlaying_array.tracks.filter((track) => track.id === action.payload)[0];

          // if the track can be found. By toggling between playlists we
          // may not be able to get the song object from the action.payload (songID)
          // as the playlistPreviewed object isnt what it used to be
          if (songNotPlaying_track !== undefined) {
            songNotPlaying_track.isPlaying = false;
            songNotPlaying_track.previewElapsed = 0;
          }

          console.log(
            `%c ${JSON.stringify(songNotPlaying_track)}`,
            "background-color: grey; color: red; font-size: 12px;"
          );
        }

        return Object.assign({}, state, {
          playlistPreviewed: songNotPlaying_array,
        });
      }
      break;

    // set 'isPlaying = true' in the PLAYLIST object
    case types.PLAYLIST_SET_PLAYLIST_AS_NOW_PLAYING:
      let playlistNowPlaying_array = cloneDeep(state.playlists);

      // will be null on first selected playlist -
      // i.e no playlist playing yet
      if (action.payload !== null) {
        playlistNowPlaying_array.filter((playlist) => playlist.id === action.payload)[0].isBeingPreviewed = true;
      }

      return Object.assign({}, state, {
        playlists: playlistNowPlaying_array,
      });
      break;

    // set 'isPlaying = false' in the PLAYLIST object
    case types.PLAYLIST_SET_PLAYLIST_AS_NOT_PLAYING:
      let playlistNotPlaying_array = cloneDeep(state.playlists);

      // will be null on first selected playlist -
      // i.e no playlist is playing yet to set as 'not playing'
      if (action.payload !== null) {
        playlistNotPlaying_array.filter((playlist) => playlist.id === action.payload)[0].isBeingPreviewed = false;
      }

      return Object.assign({}, state, {
        playlists: playlistNotPlaying_array,
      });
      break;

    case types.LISTEN_IN_PLAYLIST:
      return Object.assign({}, state, {
        listenIn: action.payload,
      });
    default:
      return state;
  }

  return state;
};

export default playlistsReducer;
