import * as types from "./types";

export function changeContainerHeight(newHeight) {
  return {
    type: types.CHANGE_CONTAINER_HEIGHT,
    payload: newHeight,
  };
}

export function changeActiveMenuItem(newItem, source) {
  return {
    type: types.CHANGE_ACTIVE_MENU_ITEM,
    payload: newItem,
    meta: {
      MP_Action: source,
      MP_Payload: {
        Link: newItem,
      },
    },
  };
}

export function changeUserMenu(newValue) {
  return {
    type: types.CHANGE_USER_MENU,
    payload: newValue,
  };
}

export function changeMobileMenu(newValue) {
  return {
    type: types.CHANGE_MOBILE_MENU,
    payload: newValue,
  };
}

export function showSpinner() {
  return {
    type: types.SHOW_SPINNER,
  };
}

export function hideSpinner() {
  return {
    type: types.HIDE_SPINNER,
  };
}

export function showNotification(message, isError, isSticky) {
  return {
    type: types.SHOW_NOTIFICATION,
    payload: { message, isError, isSticky },
  };
}

export function hideNotification() {
  return {
    type: types.HIDE_NOTIFICATION,
  };
}

export function changeForgot(newValue) {
  return {
    type: types.CHANGE_FORGOT,
    payload: newValue,
  };
}

export function checkBuild(apiValue) {
  return {
    type: types.CHECK_BUILD,
    payload: apiValue,
  };
}

export const showModal = (payload) => ({
  type: types.SHOW_MODAL,
  payload,
});

export const hideModal = () => ({
  type: types.SHOW_MODAL,
});

export const showFlashMessage = (payload) => ({
  type: types.SHOW_FLASH_MESSAGE,
  payload,
});

export const hideFlashMessage = () => ({
  type: types.HIDE_FLASH_MESSAGE,
});
