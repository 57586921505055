const port = "443";
const ENV = {
  PRODUCTION: {
    HOST_ROCK_API: "https://rock.ambie.fm",
    ALBUM_ART: "https://play.ambie.fm/",
    WEB_SOCKET_URL: "wss://rock.ambie.fm/events",
  },
  PREPRODUCTION: {
    HOST_ROCK_API: "https://rock.ambie.fm",
    ALBUM_ART: "https://play.ambie.fm/",
    WEB_SOCKET_URL: "wss://rock.ambie.fm/events",
  },
  STAGING: {
    HOST_ROCK_API: "https://rock-stage.ambie.fm",
    // HOST_ROCK_API: "http://localhost:7783",
    ALBUM_ART: "https://stage.ambie.fm/",
    WEB_SOCKET_URL: "wss://rock-stage.ambie.fm/events",
  },
  DEVELOPMENT: {
    // new environment from here
    HOST_ROCK_API: "http://localhost:7783",
    ALBUM_ART: "https://stage.ambie.fm/",
    WEB_SOCKET_URL: "http://localhost:7783/events",
  },
};
export const SLACK_MATT_USER_ID = WP_SLACK_MATT_USER_ID;
export const SATISMETER_WRITE_KEY = WP_SATISMETER_WRITE_KEY;
export const AUDIO_PREFIX = WP_AUDIO_PREFIX;

export const VERSION_CHECK_INTERVAL = 2; //hours
export const DEFAULT_TIMEZONE = "Europe/London";
export const BUILD_VERSION = WEBPACK_VERSION;
export const HOST = "https://kruo3njzbf.execute-api.eu-west-1.amazonaws.com";
export const MEDIA_COVER_CF_URL = WP_MEDIA_CF_URL;
export const PORT = 443;
export const PREFIX = AMBIE_ENV === "production" || AMBIE_ENV === "preproduction" ? "/v1" : "/staging";

//get environement variables
export const getEnvironment = () => {
  if (AMBIE_ENV === "development") {
    return ENV.DEVELOPMENT;
  } else if (AMBIE_ENV === "staging") {
    return ENV.STAGING;
  } else if (AMBIE_ENV === "production") {
    return ENV.PRODUCTION;
  } else if (AMBIE_ENV === "preproduction") {
    return ENV.STAGING;
  } else {
    return;
  }
};

export const LOCAL_STORAGE_USER = "AMBIE_USER";
export const LOCAL_STORAGE_USER_TOKEN = "AMBIE_USER_TOKEN";
export const LOCAL_STORAGE_USER_REFRESH_TOKEN = "AMBIE_USER_REFRESH_TOKEN";
export const LOCAL_STORAGE_SKIPS = "AMBIE_SKIPS";
export const MAX_SKIPS = 3;
export const SKIP_TIMEOUT_MINS = 5;
export const GENERAL_SUCCESS_MESSAGE = "Your changes have been successfully saved";
export const MINIPLAYER_UPDATE_MESSAGE = "Please allow a few seconds for the player status to update";
export const GENERAL_ERROR_MESSAGE = "There was an error while trying to save your changes";
export const GENERAL_REQUEST_ERROR_MESSAGE = "There was an error while fetching the data";
export const DEFAULT_NOTIFICATION_HIDE_TIMEOUT = 5 * 1000;
export const DEFAULT_TIMEOUT = 1000 * 5;
export const DEFAULT_TIMEOUT_INTECEPTORS = 1000 * 20;
export const LOCAL_STORAGE_FROZEN_CLIENT = "AMBIE_FROZEN_CLIENT";
export const LOCAL_STORAGE_FREEZE_MODAL = "AMBIE_FREEZE_MODAL";

export const ALBUM_ART = AMBIE_ENV === "production" ? "http://play.ambie.fm/media/" : "http://stage.ambie.fm/media/";
export const SECONDS_TILL_REFRESH = 15;
export const CLONE_OPTIONS = {
  SCHEDULE: "schedule",
};

export const AWS_CONFIGURATION = {
  poolId: "eu-west-1:aba69286-2d9d-477e-b400-ed629595cea8",
  host: "a117f4qbnqd0g2.iot.eu-west-1.amazonaws.com",
  region: "eu-west-1",
  topicPrefix: "ambie_device_browser_",
};

// ANALYTICS
export const ANALYTICS_DEV_DOMAINS = [
  "localhost",
  "dashboard-stage.ambie.fm",
  "staging.dashboard.ambie.fm",
  "stage.dashboard.ambie.fm",
  "beta.dashboard.ambie.fm",
];
export const ANALYTICS_ENVIRONMENT = ANALYTICS_DEV_DOMAINS.includes(
  window && window.location && window.location.hostname
)
  ? "DEV"
  : "PRODUCTION";
export const GOOGLE_ANALYTICS_IDS = {
  DEV: "UA-86808590-5",
  PRODUCTION: "UA-86808590-2",
};
export const MIXPANEL_IDS = {
  DEV: "63049a1a609bf95de5a02dfeae9d9cd1", // OLD [DEV] Ambie Hub - "5a0fb10afc7cd9ca03eec62168594070",
  PRODUCTION: "a749fe562f03767351339078f548a92c", // Ambie
};
export const GOOGLE_ANALYTICS_ID = GOOGLE_ANALYTICS_IDS[ANALYTICS_ENVIRONMENT];
export const MIXPANEL_ID = MIXPANEL_IDS[ANALYTICS_ENVIRONMENT];

// MOODS
const colors = {
  moodRelaxed: "#34CFCD",
  moodHappy: "#FFDB61",
  moodLively: "#C160FF",
  moodParty: "#A841D6",
  moodSpecial: "#F5663C",
  moodAmbient: "#65E2E2",

  // spa & wellness moods
  moodMildRelaxation: "#EEABFA",
  moodDeepRelaxation: "#65E2E2",
  moodEnergyActivation: "#F5663C",
  moodSpaWellness: "#90EE90",
};

const MOOD_MAP = {
  ambient: {
    name: "ambient",
    description: "creating a calm atmosphere",
    tempo: 1,
    displayName: "Ambient",
    color: colors.moodAmbient,
    gradient: {
      from: "#8087d4",
      to: "#79d5dc",
    },
    shadow: "rgba(124, 175, 216, 0.55)",
    image: "https://ambie-logo.s3.eu-west-1.amazonaws.com/ambient.jpeg",
  },
  relaxed: {
    name: "relaxed",
    description: "slowing down the tempo",
    tempo: 2,
    displayName: "Relaxed",
    color: colors.moodRelaxed,
    gradient: {
      from: "#3471ae",
      to: "#68c9bb",
    },
    shadow: "rgba(78, 158, 181, 0.55)",
    image: "https://ambie-logo.s3.eu-west-1.amazonaws.com/relaxed.jpeg",
  },
  happy: {
    name: "happy",
    description: "brightening the mood",
    tempo: 3,
    displayName: "Happy",
    color: colors.moodHappy,
    gradient: {
      from: "#ff5d5f",
      to: "#f1ce2e",
    },
    shadow: "rgba(248, 150, 70, 0.55)",
    image: "https://ambie-logo.s3.eu-west-1.amazonaws.com/happy.jpeg",
  },
  lively: {
    name: "lively",
    description: "stepping up the pace",
    tempo: 4,
    displayName: "Lively",
    color: colors.moodLively,
    gradient: {
      from: "#f938ab",
      to: "#fc8b82",
    },
    shadow: "rgba(250, 98, 150, 0.55)",
    image: "https://ambie-logo.s3.eu-west-1.amazonaws.com/lively.jpeg",
  },
  party: {
    name: "party",
    description: "creating a party atmosphere",
    tempo: 5,
    displayName: "Party Vibes",
    color: colors.moodParty,
    gradient: {
      from: "#a147ef",
      to: "#dc5bb7",
    },
    shadow: "rgba(191, 84, 194, 0.55)",
    image: "https://ambie-logo.s3.eu-west-1.amazonaws.com/party.jpeg",
  },
  special: {
    name: "special",
    description: "a special occasion",
    tempo: 6,
    displayName: "Special Occasion",
    color: colors.moodSpecial,
    gradient: {
      from: "#f12e8d",
      to: "#ff8026",
    },
    shadow: "rgba(243, 69, 102, 0.55)",
    image: "https://ambie-logo.s3.eu-west-1.amazonaws.com/special-occasion.jpeg",
  },
  spaWellness: {
    name: "SpaAndWellness",
    description: "stepping up the pace",
    tempo: 7,
    displayName: "Spa & Wellness",
    color: colors.moodSpaWellness,
    gradient: {
      from: "#f12e8d",
      to: "#ff8026",
    },
    shadow: "rgba(78, 158, 181, 0.55)",
    image: "https://ambie-logo.s3.eu-west-1.amazonaws.com/spa-and-wellness.jpg",
  },
  mildRelaxation: {
    name: "mildRelaxation",
    description: "mid-length spa treatments",
    tempo: 8,
    displayName: "Mild Relaxation",
    color: colors.moodMildRelaxation,
    gradient: {
      from: "#f12e8d",
      to: "#ff8026",
    },
    shadow: "rgba(78, 158, 181, 0.55)",
    image: "https://ambie-logo.s3.eu-west-1.amazonaws.com/lively-2.jpeg",
  },
  deepRelaxation: {
    name: "deepRelaxation",
    description: "longer spa treatments",
    tempo: 9,
    displayName: "Deep Relaxation",
    color: colors.moodDeepRelaxation,
    gradient: {
      from: "#f12e8d",
      to: "#ff8026",
    },
    shadow: "rgba(78, 158, 181, 0.55)",
    image: "https://ambie-logo.s3.eu-west-1.amazonaws.com/ambient.jpeg",
  },
  energyActivation: {
    name: "energyActivation",
    description: "shorter spa treatments",
    tempo: 10,
    displayName: "Energy Activation",
    color: colors.moodEnergyActivation,
    gradient: {
      from: "#f12e8d",
      to: "#ff8026",
    },
    shadow: "rgba(78, 158, 181, 0.55)",
    image: "https://ambie-logo.s3.eu-west-1.amazonaws.com/special-occasion.jpeg",
  },
};

const MOOD_MAPSS = {
  ambient: {
    name: "ambient",
    description: "creating a calm atmosphere",
    tempo: 0,
    displayName: "Ambient",
    color: "#7ea1d7",
    gradient: {
      from: "#8087d4",
      to: "#79d5dc",
    },
    shadow: "rgba(124, 175, 216, 0.55)",
  },
  relaxed: {
    name: "relaxed",
    description: "slowing down the tempo",
    tempo: 1,
    displayName: "Relaxed",
    color: "#33a4a0",
    gradient: {
      from: "#3471ae",
      to: "#68c9bb",
    },
    shadow: "rgba(78, 158, 181, 0.55)",
  },
  happy: {
    name: "happy",
    description: "brightening the mood",
    tempo: 2,
    displayName: "Happy",
    color: "#f4b33a",
    gradient: {
      from: "#ff5d5f",
      to: "#f1ce2e",
    },
    shadow: "rgba(248, 150, 70, 0.55)",
  },
  lively: {
    name: "lively",
    description: "stepping up the pace",
    tempo: 3,
    displayName: "Lively",
    color: "#f9955d",
    gradient: {
      from: "#f938ab",
      to: "#fc8b82",
    },
    shadow: "rgba(250, 98, 150, 0.55)",
  },

  party: {
    name: "party",
    description: "creating a party atmosphere",
    tempo: 4,
    displayName: "Party Vibes",
    color: "#c55dbe",
    gradient: {
      from: "#a147ef",
      to: "#dc5bb7",
    },
    shadow: "rgba(191, 84, 194, 0.55)",
  },
  special: {
    name: "special",
    description: "a special occasion",
    tempo: 5,
    displayName: "Special Occasion",
    color: "#f34566",
    gradient: {
      from: "#f12e8d",
      to: "#ff8026",
    },
    shadow: "rgba(243, 69, 102, 0.55)",
  },
  spaWellness: {
    name: "SpaAndWellness",
    description: "stepping up the pace",
    tempo: 6,
    displayName: "Spa & Wellness",
    color: "#f9955d",
    gradient: {
      from: "#f12e8d",
      to: "#ff8026",
    },
    shadow: "rgba(78, 158, 181, 0.55)",
  },
  mildRelaxation: {
    name: "mildRelaxation",
    description: "stepping up the pace",
    tempo: 7,
    displayName: "Mild Relaxation",
    color: "#f9955d",
    gradient: {
      from: "#f12e8d",
      to: "#ff8026",
    },
    shadow: "rgba(78, 158, 181, 0.55)",
  },
  deepRelaxation: {
    name: "deepRelaxation",
    description: "stepping up the pace",
    tempo: 8,
    displayName: "Deep Relaxation",
    color: "#f9955d",
    gradient: {
      from: "#f12e8d",
      to: "#ff8026",
    },
    shadow: "rgba(78, 158, 181, 0.55)",
  },
  energyActivation: {
    name: "energyActivation",
    description: "stepping up the pace",
    tempo: 9,
    displayName: "Energy Activation",
    color: "#f9955d",
    gradient: {
      from: "#f12e8d",
      to: "#ff8026",
    },
    shadow: "rgba(78, 158, 181, 0.55)",
  },
};

export const MOODS = {
  low: MOOD_MAP.relaxed,
  medium: MOOD_MAP.happy,
  high: MOOD_MAP.lively,
  ambient: MOOD_MAP.ambient,
  relaxed: MOOD_MAP.relaxed,
  happy: MOOD_MAP.happy,
  lively: MOOD_MAP.lively,
  party: MOOD_MAP.party,
  special: MOOD_MAP.special,
  spaWellness: MOOD_MAP.spaWellness,
  mildRelaxation: MOOD_MAP.mildRelaxation,
  deepRelaxation: MOOD_MAP.deepRelaxation,
  energyActivation: MOOD_MAP.energyActivation,
};
