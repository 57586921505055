import store from "@store";
import { BUILD_VERSION, LOCAL_STORAGE_USER } from "@config";
import { showFlashMessage } from "@actions/layout-actions";
import axiosInterceptors from "../utils/interceptors";

export const clearSiteData = async () => {
  try {
    const response = await axiosInterceptors.post("clear-site-data", {
      types: ["cookies", "storage"],
    });
  } catch (e) {
    console.log("TCL: clearSiteData -> e", e);
  }
};

export const getLatestVersion = async () => {
  try {
    const response = await axiosInterceptors.get("/version");
    if (response.data && response.data.version && response.data.version !== BUILD_VERSION) {
      store.dispatch(
        showFlashMessage({
          status: "info",
          message: "New version available. Click to update!",
          backdrop: true,
          onClick: async () => {
            const userInfo = localStorage.getItem(LOCAL_STORAGE_USER);
            await clearSiteData();
            if (userInfo && typeof userInfo === "string") {
              localStorage.setItem(LOCAL_STORAGE_USER, userInfo);
            }
            window.location.reload();
          },
        })
      );
    }
    return response.data && response.data.version;
  } catch (e) {
    return BUILD_VERSION;
  }
};
