import React from "react";
import { render } from "react-dom";
import { Router } from "react-router-dom";

import { Provider } from "react-redux";
import Intercom from "react-intercom";
import * as Sentry from "@sentry/browser";

import store from "./store";
import router from "./router";
import history from "./history";

import * as mixPanelHelper from "./common/mixPanelHelper";
import * as gaAnalyticsHelper from "./common/gaAnalyticsHelper";

import { showFlashMessage } from "@actions/layout-actions";
import { FreshchatIntegration } from "./components/freshchat";

if (AMBIE_ENV === "production") {
  window.mixpanel = mixPanelHelper.init();
  gaAnalyticsHelper.init();
  Sentry.init({
    dsn: "https://f9fd9d1c5dec4f17bf7cfa29f3e5ece8@sentry.io/1476617",
  });
  const originalConsoleError = console.error;
  console.error = (message, error) => {
    Sentry.captureException(error);
    originalConsoleError.apply(this, arguments);
  };
} else {
  gaAnalyticsHelper.init("FAKE_ID_FOR_LOCALHOST");
  window.mixpanel = mixPanelHelper.init();
}

if (navigator.serviceWorker) {
  window.addEventListener("load", () => {
    navigator.serviceWorker
      .register("/service-worker.js")
      .then((registration) => {
        console.log("ServiceWorker registration successful with scope:", registration.scope);
      })
      .catch((error) => {
        console.log("ServiceWorker registration failed:", error);
      });
  });
} else {
  store.dispatch(
    showFlashMessage({
      duration: 10000,
      status: "info",
      message: "Your browser is not supported. We advise using Google Chrome for the best experience.",
    })
  );
}

//
// Boootstrap the application using redux and react-router
//
render(
  <React.Fragment>
    <Intercom
      appID="j4p8mmo7"
      r={window.location.href}
      alignment="left"
      horizontal_padding={20}
      vertical_padding={20}
      hide_default_launcher
      custom_launcher_selector="#ambie-intercom-widget"
    />
    <Router history={history}>
      <Provider store={store}>{router}</Provider>
    </Router>
    <FreshchatIntegration />
  </React.Fragment>,
  document.getElementById("app")
);
