import * as types from "./types";

export function changeChannels(newChannels) {
  return {
    type: types.CHANGE_CHANNELS,
    payload: newChannels,
  };
}

export function getChannelsAction(channels) {
  return {
    type: types.GET_CHANNEL_PLAYLISTS,
    payload: channels,
  };
}

export function changeFavourites(favourites) {
  return {
    type: types.GET_FAVOURITE_PLAYLISTS,
    payload: favourites,
  };
}

export function changeShowPreviewScreen(newValue) {
  return {
    type: types.CHANGE_SHOW_PREVIEW_SCREEN,
    payload: newValue,
  };
}

export function changeCurrentChannelIndex(newValue) {
  return {
    type: types.CHANGE_CURRENT_CHANNEL_INDEX,
    payload: newValue,
  };
}

export function changeShowCuratorContactForm(newValue) {
  return {
    type: types.CHANGE_SHOW_CURATOR_CONTACT_FORM,
    payload: newValue,
  };
}

export function addToFavourites(playlist) {
  return {
    type: types.ADD_TO_FAVOURITES,
    payload: playlist,
  };
}

export function removeFromFavourites(playlist) {
  return {
    type: types.REMOVE_FROM_FAVOURITES,
    payload: playlist,
  };
}

export function pauseAllPlaylists() {
  return {
    type: types.PAUSE_ALL_PLAYLISTS,
    payload: null,
  };
}

export function playOnePlaylist(playlist) {
  return {
    type: types.PLAY_ONE_PLAYLIST,
    payload: playlist,
  };
}

export function checkIsFavourite() {
  return {
    type: types.CHECK_IS_A_FAVOURITE,
    payload: null,
  };
}

export function updateProgressBar(playlistId) {
  return {
    type: types.UPDATE_PROGRESS_BAR,
    payload: playlistId,
  };
}

export function resetProgressBar(playlistId) {
  return {
    type: types.RESET_PROGRESS_BAR,
    payload: playlistId,
  };
}

/***
 * @param trackDetails {array}
 * */
export function displayPreviewTrackDetails(trackDetails) {
  return {
    type: types.UPDATE_PREVIEW_DETAILS,
    payload: trackDetails,
  };
}
