import React, { useState } from "react";
import { NavLink } from "react-router-dom";
import PropTypes from "prop-types";
import { connect } from "react-redux";

import CalendarSvg from "./icons/CalendarSvg";
import CurationSvg from "./icons/CurationSvg";
import LocationSvg from "./icons/LocationSvg";
import InsightsSvg from "./icons/InsightsSvg";
import SmartphoneSvg from "./icons/SmartphoneSvg";
import Modal from "@partials/Modal/modal";
import { FreshchatIntegration } from "../freshchat";
import { AccessWithoutTeam } from "../permissions/index";

const SideMenu = (props) => {
  const [qrCode, setQrcode] = useState(false);
  const [opSystem, setOpSystem] = useState("ios");
  return (
    <>
      {/* QR Code mode */}
      <Modal width="600px" height="600px" show={qrCode} handleDismiss={() => setQrcode(false)}>
        <div className="modal-body full-width side-menu-modal">
          <div>
            <h3>Get Ambie mobile app</h3>
            <p>Scan the code below with the mobile camera or with a QR reader app.</p>
          </div>
          <div>
            <div className="side-menu-modal-tabs">
              <button
                className={`side-menu-modal-tab-item ${opSystem === "ios" && "active"}`}
                onClick={() => setOpSystem("ios")}
              >
                iOS
              </button>
              <button
                className={`side-menu-modal-tab-item ${opSystem === "android" && "active"}`}
                onClick={() => setOpSystem("android")}
              >
                Android
              </button>
            </div>

            <div>
              {opSystem === "ios" ? (
                <img src="/images/qr-code/ios.png" alt="ios" width="250px" height="250px" />
              ) : (
                <img src="/images/qr-code/android.png" alt="android" width="250px" height="250px" />
              )}
            </div>
          </div>

          <div className="flex justify-content-center align-items-center">
            <div
              className="side-menu-modal-logo"
              onClick={() => {
                window.open(`https://apps.apple.com/gb/app/my-ambie/id1638901418`, "_blank");
              }}
            >
              <img src="/images/qr-code/app-stores.png" alt="ios" width="auto" height="50px" />
            </div>
            <div
              className="side-menu-modal-logo"
              onClick={() => {
                window.open(`https://play.google.com/store/apps/details?id=com.ambie.ambie`, "_blank");
              }}
            >
              <img src="/images/qr-code/play-store.png" alt="android" width="auto" height="77px" />
            </div>
          </div>
        </div>
      </Modal>

      <nav className={`side-menu${props.showMigrationBar ? " side-menu-warning" : ""}`}>
        <div className="side-menu-logo-container">
          <NavLink className="flex-column menu-item" activeClassName="active" href="/" to="/">
            <img className="side-menu-logo" color="white" src="/images/logo/ambie-white.svg" alt="Ambie" />
          </NavLink>
        </div>
        <div
          className={`flex-column full-width side-menu-links ${
            props.freezeClientInfo && props.freezeClientInfo.allLocations && "disable-side-menu"
          }`}
        >
          <div className="side-menu-icon">
            <NavLink
              className="flex-column"
              activeClassName={`${!props.freezeClientInfo && props.freezeClientInfo.allLocations && "active"}`}
              href="/locations"
              to="/locations"
              onClick={() => {
                window.location.href = `/locations`;
              }}
            >
              <LocationSvg />
              Locations
            </NavLink>
          </div>

          <div className="side-menu-icon">
            <NavLink
              className="flex-column"
              activeClassName={`${!props.freezeClientInfo && props.freezeClientInfo.allLocations && "active"}`}
              href="/schedules"
              to="/schedules"
            >
              <CalendarSvg />
              Schedules
            </NavLink>
          </div>

          {AccessWithoutTeam().includes(props.userInfo.userType) && (
            <>
              <div className="side-menu-icon">
                <NavLink
                  className="flex-column"
                  activeClassName={`${!props.freezeClientInfo && props.freezeClientInfo.allLocations && "active"}`}
                  href="/curation"
                  to="/curation"
                >
                  <CurationSvg />
                  Playlists
                </NavLink>
              </div>
              <div className="side-menu-icon">
                <NavLink
                  className="flex-column"
                  activeClassName={`${!props.freezeClientInfo && props.freezeClientInfo.allLocations && "active"}`}
                  href="/insights"
                  to="/insights"
                >
                  <InsightsSvg />
                  Insights
                  <span className="side-menu-beta">beta</span>
                </NavLink>
              </div>
            </>
          )}
        </div>
        <div className="flex-column side-menu-logout">
          <div
            className={`side-menu-icon side-menu-mobile-icon ${
              props.freezeClientInfo && props.freezeClientInfo.allLocations && "disable-side-menu"
            }`}
            style={{ marginBottom: "1rem" }}
          >
            <NavLink
              href="#"
              to="#"
              activeClassName=""
              className={`flex-column align-items-center`}
              onClick={() => {
                setQrcode(true);
              }}
            >
              <SmartphoneSvg fill="#fff" width="40" height="40" />
              Mobile App
            </NavLink>
          </div>

          <div className="side-menu-icon">
            {/* <IntercomWidget businessName={props.businessName} userId={props.userId} buttonText="Support" /> */}
            <FreshchatIntegration name={props.name} />
          </div>
          <hr className="margin-bottom-xs margin-top-none full-width" />
          <div className="side-menu-icon">
            <button
              type="button"
              className="btn-clear text-center flex-center-center flex-column side-menu-logout-button"
              onClick={props.handleLogout}
            >
              Logout
            </button>
          </div>
        </div>
      </nav>
    </>
  );
};

const storeToProps = (store) => {
  return {
    userInfo: store.authState.userInfo,
    freezeClientInfo: store.authState.freezeClientInfo,
    showMigrationBar: store.authState.showMigrationBar,
  };
};

SideMenu.propTypes = {
  businessName: PropTypes.string,
  userId: PropTypes.number,
  handleLogout: PropTypes.func.isRequired,
  userToken: PropTypes.string,
  name: PropTypes.string,
  username: PropTypes.string,
};

export default connect(storeToProps)(SideMenu);
