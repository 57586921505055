import React, { Component } from "react";
import { Link } from "react-router-dom";

class Footer extends Component {
  constructor() {
    super();
  }

  render() {
    return (
      <div className="footer">
        <footer id="main-footer">
          <nav className="navbar">
            <ul className="footer__list ">
              <li className="footer__list-item">
                <a
                  href="https://www.ambie.fm/terms/"
                  target="_blank"
                  className="footer__link menu-item-link mixpanel-link"
                  title="Terms &amp; Conditions"
                  data-mixpanelevent="Footer"
                  data-mixpaneldata="Terms &amp; Conditions"
                >
                  Terms &amp; Conditions
                </a>
              </li>
              <li className="footer__list-item">
                <a
                  href="https://www.ambie.fm/copyright/"
                  target="_blank"
                  className="footer__link menu-item-link mixpanel-link "
                  title="License Policy"
                  data-mixpanelevent="Footer"
                  data-mixpaneldata="License Policy"
                >
                  License Policy
                </a>
              </li>
              <li className="footer__list-item">
                <a
                  href="https://intercom.help/ambie/"
                  target="_blank"
                  className="footer__link menu-item-link mixpanel-link "
                  title="Support"
                  data-mixpanelevent="Footer"
                  data-mixpaneldata="Support"
                >
                  Support
                </a>
              </li>
              <li className="footer__list-item">
                <a
                  href="https://www.ambie.fm/contact/"
                  target="_blank"
                  className="footer__link menu-item-link mixpanel-link "
                  title="Contact Us"
                  data-mixpanelevent="Footer"
                  data-mixpaneldata="Contact Us"
                >
                  Contact Us
                </a>
              </li>
            </ul>
          </nav>
        </footer>
      </div>
    );
  }
}

export default Footer;
