import React from "react";

const LoadingTypewriter = (props) => (
  <div className={`block-overlay overlay-${props.show ? "show" : "hide"}`}>
    <div className="typewriter">
      <h1>Loading...</h1>
    </div>
  </div>
);

export default LoadingTypewriter;
