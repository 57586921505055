import axios from "axios";
import store from "../store";
import { changeSchedules, changeSelectedSchedule } from "../actions/schedules-actions";
import { showSpinner, hideSpinner, showNotification } from "../actions/layout-actions";
import {
  GENERAL_SUCCESS_MESSAGE,
  GENERAL_ERROR_MESSAGE,
  GENERAL_REQUEST_ERROR_MESSAGE,
  getEnvironment,
} from "../config";
import * as authApi from "./auth-api";
import axiosInterceptors from "../utils/interceptors";

export const getSchedules = (selectedScheduleName, isNewOne) => {
  store.dispatch(showSpinner());
  return axiosInterceptors
    .get(`${getEnvironment().HOST_ROCK_API}/schedules/client-dashboard/weekly-schedules`, {
      validateStatus: (status) => _handleUnauthorized(status),
    })
    .then((response) => {
      _detectUnauthorized(response);
      store.dispatch(hideSpinner());
      if (!response.data.error) {
        store.dispatch(changeSchedules(response.data));
        if (response.data && response.data.length > 0) {
          if (!selectedScheduleName) {
            getSelectedSchedule(response.data[0].id);
          } else if (isNewOne) {
            getSelectedSchedule(response.data[response.data.length - 1].id);
          }
        } else {
          store.dispatch(
            changeSelectedSchedule({
              name: "",
              schedule: [[], [], [], [], [], [], []],
            })
          );
        }
        return response.data;
      } else {
        store.dispatch(showNotification(GENERAL_REQUEST_ERROR_MESSAGE, true));
      }
    })
    .catch((err) => {
      store.dispatch(hideSpinner());
      store.dispatch(showNotification(GENERAL_REQUEST_ERROR_MESSAGE, true));
      _handleUnauthorized(err);
    });
};

export const getSelectedSchedule = (scheduleId) => {
  store.dispatch(showSpinner());

  return axiosInterceptors
    .get(`${getEnvironment().HOST_ROCK_API}/schedules/client/${scheduleId}/weekly-schedule`, {
      validateStatus: (status) => _handleUnauthorized(status),
    })
    .then((response) => {
      _detectUnauthorized(response);
      store.dispatch(hideSpinner());
      //debugger;
      store.dispatch(changeSelectedSchedule(response.data));
    })
    .catch((response) => {
      store.dispatch(hideSpinner());
      store.dispatch(showNotification(GENERAL_REQUEST_ERROR_MESSAGE, true));
    });
};

export const addSchedule = async (scheduleData) => {
  store.dispatch(showSpinner());

  try {
    const response = await axiosInterceptors.post(
      `${getEnvironment().HOST_ROCK_API}/schedules/client-dashboard/weekly-schedule`,
      scheduleData,
      {}
    );
    _detectUnauthorized(response);
    store.dispatch(hideSpinner());
    if (!response.data.error) {
      store.dispatch(showNotification(GENERAL_SUCCESS_MESSAGE));
      try {
        const schedules = await getSchedules(scheduleData.name, true);
        const newSchedule = schedules.find((s) => s.name === scheduleData.name) || schedules[schedules.length - 1];
        return newSchedule;
      } catch (e) {}
    } else {
      store.dispatch(showNotification(GENERAL_ERROR_MESSAGE, true));
    }
  } catch (e) {
    store.dispatch(hideSpinner());
    store.dispatch(showNotification(GENERAL_REQUEST_ERROR_MESSAGE, true));
  }
};

export async function updateSchedule(schedule, affectedAreas) {
  store.dispatch(showSpinner());
  try {
    const updateSchedule = await axiosInterceptors.patch(
      `${getEnvironment().HOST_ROCK_API}/schedules/client/${schedule.id}/weekly-schedule`,
      {
        schedule: schedule.schedule,
        affectedAreas,
      }
    );

    store.dispatch(hideSpinner());
    store.dispatch(showNotification(GENERAL_SUCCESS_MESSAGE));

    return updateSchedule;
  } catch (error) {
    store.dispatch(hideSpinner());
    store.dispatch(showNotification(GENERAL_ERROR_MESSAGE, true));
    _handleUnauthorized(error.message);
  }
}

export const assignScheduleToArea = (areaId, locationId, scheduleId) => {
  store.dispatch(showSpinner());
  return axiosInterceptors
    .post(
      `${getEnvironment().HOST_ROCK_API}/schedules/area-week-schedule`,
      {
        areaId: areaId,
        locationId: locationId,
        weekScheduleId: scheduleId,
      },
      {
        validateStatus: (status) => _handleUnauthorized(status),
      }
    )
    .then((response) => {
      store.dispatch(hideSpinner());
      store.dispatch(showNotification(GENERAL_SUCCESS_MESSAGE));
    })
    .catch((response) => {
      store.dispatch(hideSpinner());
      store.dispatch(showNotification(GENERAL_ERROR_MESSAGE, true));
      console.error("Error: ", response);
    });
};

const _handleUnauthorized = (error) => {
  if (error === "Unauthorized" || error === 401) {
    authApi.logout();
    return false;
  }
  return true;
};

const _detectUnauthorized = (response) => {
  if (response.data.error && response.data.error == "Unauthorized") {
    throw new Error(response.data.error);
  }
};
