import * as types from "@actions/types";

const initalState = {
  feedbackTrack: null,
  feedbackType: null,
  feedbackMessage: "",
  feedbackDone: false,
};

const feedbackReducer = (state = initalState, action) => {
  switch (action.type) {
    case types.CHANGE_FEEDBACK_MESSAGE:
      return {
        ...state,
        feedbackMessage: action.payload,
        feedbackDone: false,
      };
    case types.CHANGE_FEEDBACK_TYPE:
      return {
        ...state,
        feedbackType: action.payload,
        feedbackDone: false,
      };
    case types.CHANGE_FEEDBACK_TRACK:
      return {
        ...state,
        feedbackTrack: action.payload,
        feedbackDone: false,
      };
    case types.CHANGE_FEEDBACK_DONE:
      return {
        ...state,
        feedbackTrack: {},
        feedbackType: null,
        feedbackMessage: "",
        feedbackDone: true,
      };
    case types.CLEAR_FEEDBACK:
      return {
        ...initalState,
      };
    default:
      return state;
  }
};

export default feedbackReducer;
