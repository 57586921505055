// style components
import axiosInterceptors from "../utils/interceptors";

/**
 * Client IP address
 */
export const getClientIpAddress = () => axiosInterceptors.get("https://ipinfo.io/json?token=8a4ff444d24e1b", {});

/**
 * Clear storage
 */
export const getClearlocalStorage = () => {
  localStorage.clear();
};

/**
 * Error message that record in sentry
 */
export const getLoginUserRecord = (authReducers) => {
  const authReducersHasData = authReducers && authReducers.user;
  const authReducersRecord =
    authReducersHasData &&
    `Organisation: ${authReducersHasData && authReducersHasData.businessName}\nClientId: ${
      authReducersHasData && authReducersHasData.clientId
    }\nUser: ${authReducersHasData && authReducersHasData.userName}\nUsername: ${
      authReducersHasData && authReducersHasData.username
    }`;
  return `${new Date()}\n${authReducersRecord}`;
};
