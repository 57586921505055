import React, { Fragment } from "react";

import { FaTimes } from "react-icons/fa";

import Backdrop from "../Backdrop/backdrop";

const Modal = (props) => {
  const flexJustifyOptions = ["flex-start", "flex-end", "space-between", "space-around", "space-evenly", "center"];

  const modalStyle = {
    "--height": props.height || window.innerHeight * 0.9 + "px",
    "--width": props.width || window.innerWidth * 0.9 + "px",
    textAlign: props.textAlign || "center",
    justifyContent: flexJustifyOptions[props.flexSpacing] || props.flexSpacing || flexJustifyOptions[4],
  };

  const animation = props.animation || "fadeIn";
  let cssClasses = `animated-no-fill duration04 ${animation} modal-container`;
  if (props.cssClass) cssClasses += ` ${props.cssClass}`;
  if (props.dark) cssClasses += ` dark`;
  if (props.cssClassName) cssClasses += ` ${props.cssClassName}`;

  return !props.show ? null : (
    <Fragment>
      <Backdrop show={props.show} hide={props.handleDismiss} />
      <div style={modalStyle} className={cssClasses}>
        <div className="modal-inner">
          <button className="btn-clear modal-close" onClick={props.handleDismiss}>
            <FaTimes color={props.dark ? "white" : "darkslategrey"} size={25} />
          </button>
          {props.children}
        </div>
      </div>
    </Fragment>
  );
};

export default Modal;

export const FrozenClientModal = (props) => {
  const flexJustifyOptions = ["flex-start", "flex-end", "space-between", "space-around", "space-evenly", "center"];

  const modalStyle = {
    "--height": props.height || window.innerHeight * 0.9 + "px",
    "--width": props.width || window.innerWidth * 0.9 + "px",
    textAlign: props.textAlign || "left",
    justifyContent: flexJustifyOptions[props.flexSpacing] || props.flexSpacing || flexJustifyOptions[6],
  };

  const animation = props.animation || "fadeIn";
  let cssClasses = `animated-no-fill duration04 ${animation} modal-container`;
  if (props.cssClass) cssClasses += ` ${props.cssClass}`;
  if (props.dark) cssClasses += ` dark`;
  if (props.cssClassName) cssClasses += ` ${props.cssClassName}`;

  return !props.show ? null : (
    <Fragment>
      <Backdrop show={props.show} hide={props.handleDismiss} />

      <div style={modalStyle} className={cssClasses}>
        <div className="modal-inner">
          {/* <button className="btn-clear modal-close" onClick={props.handleDismiss}>
            <FaTimes color={props.dark ? "white" : "darkslategrey"} size={25} />
          </button> */}
          {props.children}
        </div>
      </div>
    </Fragment>
  );
};
