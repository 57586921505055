import { loginSuccess, logoutSuccess, freezeClient, showMigrationWarningModal } from "@actions/auth-actions";
import { setLoginFormValid, setLoginFormInvalid } from "@actions/login-actions";
import * as schedulesActions from "@actions/schedules-actions";
import * as locationsActions from "@actions/locations-actions";
import * as channelsActions from "@actions/channels-actions";
import * as playlistsActions from "@actions/playlists-actions";
import { getEnvironment } from "@config";
import store from "@store";
import browserHistory from "@app/history";
import {
  storeUser,
  storeToken,
  storeRefreshToken,
  storeFrozenClient,
  storeMigrationStatus,
} from "../utils/localStorage";
import axiosInterceptors from "../utils/interceptors";
import { getClearlocalStorage } from "../utils/services";
import { showSpinner, hideSpinner } from "@actions/layout-actions";

export function login(credentials) {
  store.dispatch(showSpinner(true));
  return axiosInterceptors
    .post(`${getEnvironment().HOST_ROCK_API}/auth/login`, {
      username: credentials.username,
      password: credentials.password,
      application: "dashboard",
    })
    .then((response) => {
      store.dispatch(setLoginFormValid());
      const userDetail = response.data.user.users_user;
      const clientDetail = response.data.user.businesses_client;
      const isMigrated = response.data.user.businesses_client.is_migrated;
      let userInfo = {
        userToken: response.data.accessToken,
        refreshToken: response.data.refreshToken,
        userName: userDetail.name,
        created: userDetail.date_joined,
        email: userDetail.email,
        clientId: clientDetail.id,
        businessName: clientDetail.name,
        businessImage: "https://dashboard.ambie.fm/images/logo-transparent.png",
        userType: response.data.user.permission_level.permission_level,
        username: userDetail.username,
        userId: userDetail.id,
        isFrozen: clientDetail.is_frozen,
      };
      storeUser(userInfo);
      storeToken(userInfo.userToken);
      storeRefreshToken(userInfo.refreshToken);
      storeFrozenClient({
        status: userInfo.isFrozen,
        allLocations: userInfo.isFrozen,
      });
      store.dispatch(loginSuccess(userInfo));
      store.dispatch(freezeClient({ status: userInfo.isFrozen, allLocations: userInfo.isFrozen }));
      store.dispatch(showMigrationWarningModal(!isMigrated));
      store.dispatch(hideSpinner());
      browserHistory.push("/");
    })
    .catch((response) => {
      store.dispatch(hideSpinner());
      store.dispatch(setLoginFormInvalid());
    });
}

export function loginIntoClientAsAmbie(credentials) {
  store.dispatch(showSpinner(true));
  return axiosInterceptors
    .post(`${getEnvironment().HOST_ROCK_API}/auth/switch-client-for-login`, {
      username: credentials.username,
      userId: parseInt(credentials.userId, 10),
      clientId: parseInt(credentials.clientId, 10),
      permissionLevelId: 6,
      application: "dashboard",
    })
    .then((response) => {
      store.dispatch(setLoginFormValid());
      const userDetail = response.data.user.users_user;
      const clientDetail = response.data.user.businesses_client;
      let userInfo = {
        userToken: response.data.accessToken,
        refreshToken: response.data.refreshToken,
        userName: userDetail.first_name,
        created: userDetail.date_joined,
        email: userDetail.email,
        clientId: clientDetail.id,
        businessName: clientDetail.name,
        businessImage: "https://dashboard.ambie.fm/images/logo-transparent.png",
        userType: response.data.user.permission_level.permission_level,
        username: userDetail.username,
        userId: userDetail.id,
        isFrozen: clientDetail.is_frozen,
      };
      storeUser(userInfo);
      storeToken(userInfo.userToken);
      storeRefreshToken(userInfo.refreshToken);
      storeFrozenClient({
        status: userInfo.isFrozen,
        allLocations: userInfo.isFrozen,
      });
      store.dispatch(loginSuccess(userInfo));
      store.dispatch(freezeClient({ status: userInfo.isFrozen, allLocations: userInfo.isFrozen }));
      store.dispatch(hideSpinner());
      browserHistory.push("/");
    })
    .catch((response) => {
      store.dispatch(hideSpinner());
      store.dispatch(setLoginFormInvalid());
    });
}

export const requestPasswordReset = async (email) => {
  const referrer = window.location.hostname;
  try {
    const body = { email, referrer: referrer === "localhost" ? "localhost:7777" : referrer, isWeb: true };
    await axiosInterceptors.post(`${getEnvironment().HOST_ROCK_API}/auth/reset-password-request`, body);
    return true;
  } catch (e) {
    return false;
  }
};

export const resetPassword = async (token, password, passwordConfirm, email) => {
  try {
    const body = { accessToken: token, password, confirmPassword: passwordConfirm, email };
    await axiosInterceptors.post(`${getEnvironment().HOST_ROCK_API}/auth/reset-password`, body);

    return true;
  } catch (e) {
    return e.response;
  }
};

export const validatePasswordResetToken = async (token) => {
  try {
    const response = await axiosInterceptors.get(
      `${getEnvironment().HOST_ROCK_API}/auth/validate-reset-password/${token}`,
      {}
    );
    return response.data;
  } catch (e) {
    return false;
  }
};

export function logout() {
  getClearlocalStorage();
  store.dispatch(logoutSuccess());
  store.dispatch(locationsActions.changeLocations([]));
  store.dispatch(schedulesActions.changeSchedules([]));
  store.dispatch(channelsActions.changeFavourites([]));
  store.dispatch(channelsActions.changeChannels([]));
  store.dispatch(playlistsActions.addPlaylists([]));
  browserHistory.push("/login");
}

/**
 * Get user profile
 */
export async function userProfile() {
  return await axiosInterceptors.get(`${getEnvironment().HOST_ROCK_API}/users/me`, {});
}

/**
 * Get user token from mongoDB
 */
export const getUserTokenFromMongo = async (userId, accessToken) => {
  return await axiosInterceptors
    .get(`${getEnvironment().HOST_ROCK_API}/users/user-token/${userId}/${accessToken}`, {})
    .then(() => {})
    .catch((error) => {
      logout();
    });
};

export const handleUnauthorized = (status) => {
  if (status === 401 || status === 403) {
    logout();
    return false;
  }
  return true;
};
